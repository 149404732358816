import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

// Components
import InputField from '../elements/InputField'
import Dropdown from '../elements/Dropdown'
import Button from '../elements/Button'
import Modal from '../elements/Modal'

// Assets
import Loader from '../../assets/images/loader2.gif'

// API
import {catalogueReports} from '../../api'

function NewCatalogue() {
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenCreate, setIsOpenCreate] = useState(false)
    const [errors, setErrors] = useState({})
    const [inputType, setInputType] = useState('file')
    const [validationTask, setValidationTask] = useState({})
    const [canceled, setCanceled] = useState(false)

    function handleSubmit(e) {
        e.preventDefault()

        var form_element = document.getElementById('create-catalogue-form')
        var form_data = new FormData(form_element)

        catalogueReports.create(form_data)
        .then(response => {
            setIsOpenCreate(true)
        })
        .catch(error => {
            setErrors({...error})
            setIsOpenCreate(false)
        })
    }

    function handleChangeType(e) {
        setInputType(e.target.value)
    }

    function handleValidation(){
        
        let formData
        if (validationTask.id) {
            formData = new FormData()
            formData.append('task_id', validationTask.id)
        }
        else{
            let formElement = document.getElementById('create-catalogue-form')
            formData = new FormData(formElement)
            setCanceled(false)
        }

        if (!canceled) {
            catalogueReports.validate(formData)
                .then(response => {
                    if(response.status === 'REVOKED' || response.status === 'FAILURE'){
                        setValidationTask({})
                    }
                    else{
                        setValidationTask(response)
                    }
                })
                .catch(error => {
                    setIsOpen(false)
                })
        }
    }

    useEffect(()=>{
        if(validationTask.id && validationTask.status === 'PENDING'){
            handleValidation()
        }
    }, [validationTask])

    function handleCloseModal() {

        
        setIsOpen(false)

        if(validationTask.status === 'PENDING') {
            let formData = new FormData()
            formData.append('task_id', validationTask.id)
            
            catalogueReports.cancel_validation(formData)
            .catch(error => {
                console.error("It was no possible to cancel de validation", error)
            })
        }

        setValidationTask({})
        setCanceled(true)

    }

    let inputByType
    if(inputType === 'file') {
        inputByType = <InputField label="File" class="flexer-8" type="file" name="initial_file" error={errors.initial_file} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" required={true}/>
    }
    else if (inputType === 'url') {
        inputByType = <InputField label="URL" class="full-width" type="text" name="url" error={errors.url} required={true}/>
    }

    let modalInfo
    let subInfo
    if(validationTask.result){
        modalInfo = []
        for(const [lot, result] of Object.entries(validationTask.result)){
            
            subInfo = []
            for(const [horseType, text] of Object.entries(result)){
                subInfo.push(<li>{horseType} {text}</li>)
            }
            modalInfo.push(<li><strong>Lot {lot}</strong><ul>{subInfo}</ul></li>)
        }
        if(modalInfo.length === 0){
            modalInfo.push(<p className="mx-20">All horses were found :) !</p>)
        }
        else{
            modalInfo = 
            <div className='left'>
                <h4>Lots to be ignored:</h4>
                <ul>
                    {modalInfo}
                </ul>
            </div>
        }
    }
    else{
        modalInfo = [<p className="mx-20">This could take a few minutes depending on the size of the file.</p>, <img src={Loader} />]
    }

    return (
        <React.Fragment>
            <form id="create-catalogue-form" onSubmit={handleSubmit}>
                <h2 className="title is-blue caps">Create new catalogue</h2>
                <div className="flexer flexer-spaced">
                    <InputField label="Catalogue name" class="flexer-5" type="text" name="name" error={errors.name} required={true}/>
                    <Dropdown label="type" class="flexer-5" name="type" options={{file: "By file", url: "By URL"}} onChange={handleChangeType}/>
                </div>
                <div className="flexer flexer-vcenter flexer-spaced">
                    {inputByType}
                    {inputType === 'file' ? <Button type="button" text="Validate" onClick={()=>{setIsOpen(true); handleValidation()}} icon="fa fa-check" />: null }
                </div>
                <div className="flexer flexer-spaced">
                    <InputField label="Price (USD)" type="number" class="flexer-5" name="price" error={errors.price} required={true}/>
                    <InputField label="Discounted Price (USD)" type="number" class="flexer-5" name="discounted_price" error={errors.discounted_price} required={true}/>
                    
                </div>
                <div className="flexer flexer-spaced">
                    <InputField label="Initial date" type="date" class="flexer-5" name="initial_date" error={errors.initial_date} required={true}/>
                    <InputField label="Final date" type="date" class="flexer-5" name="final_date" error={errors.final_date} required={true}/>
                </div>
                <InputField label="Catalogue Image" type="file" class="full-width" name="image_file" error={errors.image_file} required={true} accept="image/*"/>
                
                <Button type="submit" text="Create Now" class="my-30" icon="fa fa-paper-plane" />
            </form>
            <Modal isOpen={isOpen}>
                <div className="center">
                    <h2 className="subtitle is-black line-margin-0">Validating all horses in the file</h2>
                    <br /><br />
                    {modalInfo}
                    <br /><br />
                    <Button type="button" className="button" text="OK" onClick={handleCloseModal} loading={Object.keys(validationTask).length === 0 ? true:  false}/>
                </div>
            </Modal>
            <Modal isOpen={isOpenCreate}>
                <div className="center">
                    <h2 className="subtitle is-black line-margin-0">Congrats!</h2>
                    <p className="mx-20">You have created a new catalogue.</p>
                    <p className="mx-20">It will become available in the "Catalogues" tab once all the information is gathered.</p>
                    <br /><br />
                    <Link to="catalogues" className="button"><i className="fa fa-check mr-10"></i>OK</Link>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default NewCatalogue