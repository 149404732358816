import config from './config'
import { handleResponse } from './handle-response'
import { checkToken, authHeader } from './helpers'

export const paymentMethodService = {
    list,
    create,
    retrieve
}

async function list() {

    await checkToken()

    const requestOptions = { method: 'GET', headers: authHeader()}
    return fetch(`${config.apiUrl}/stripe/payment_method/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

async function retrieve() {

    await checkToken()

    const requestOptions = { method: 'GET', headers: authHeader()}
    return fetch(`${config.apiUrl}/stripe/payment_method/default/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

async function create() {

    await checkToken()

    const requestOptions = { method: 'POST', headers: authHeader() }
    return fetch(`${config.apiUrl}/stripe/payment_method/`, requestOptions)
    .then(handleResponse)
    .then(user => {
        return user
    })
}