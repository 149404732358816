import React, {useState} from 'react'
import {Link} from 'react-router-dom'

// Components
import MainStructure from '../components/containers/MainStructure'
import PedigreeTree from '../components/elements/PedigreeTree'
import InputField from '../components/elements/InputField'
import Modal from '../components/elements/Modal'

// API
import {membershipReports} from '../api'

function NewPedigree() {
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenModels, setIsOpenModels] = useState(false)
    const [selected, setSelected] = useState({ml_model: 1})

    const [mlModel, setMlModel] = useState(1);

    // Function to handle the checkbox change
    const handleCheckboxChange = (event) => {
        // Set mlModel to 2 if checked, otherwise set to 1
        const model = event.target.value;
        setMlModel(model);
        setSelected({...selected, ml_model: model})
    };

    function handleSelected(horse_id, type) {
        if(horse_id){
            setSelected({...selected, [type]: [horse_id]})
        }
        else{
            let newSelected = selected
            delete selected[type]
            setSelected({...newSelected})
        }
    }

    function handleReportClick() {
        var name = document.getElementById('pedigree_name').value
        var body = {"name": name,
                    "report_config": selected,
                    }
        
        membershipReports.create_report(body)
        .then(response => {
            setIsOpen(true)
        })
        .catch(error => {
            console.error(error)
        })
    }
    
    return (
        <React.Fragment>
            <MainStructure>
                <div className="center">
                    <h2 className="huge-letters is-black">Pedigree <span className="is-bold">Analysis</span></h2>
                </div>
                <div className="component-card center">
                    <div className="left">
                        <h2 className="subtitle is-black mb-30">New Pedigree</h2>
                        <div className="flexer">
                            <div className="flexer">
                                <div className="steps mx-10"><p className="line-margin-0 center">1</p></div>
                                <InputField type="text is-black" name="name" class="line-margin-0 two-third-width remaining-mobile" label="Pedigree Name" id="pedigree_name" />
                            </div>
                            <div className="flexer right">
                                <select className="frequency-select" onChange={handleCheckboxChange}>
                                    <option value="1">Generic Models</option>
                                    <option value="2">Australian/New Zealand Models</option>
                                </select>
                                <i className="fas fa-question-circle cursor help-icon" onClick={() => setIsOpenModels(true)}></i>
                            </div>
                        </div>

                    </div>
                </div>
                <br/>
                <div className="component-card center">
                    <div className="left">
                        <div className="flexer flexer-vcenter">
                                <div className="steps mx-10"><p className="line-margin-0 center">2</p></div>
                            <p className="two-third-width input-field__label">Please select a sire and dam to gather all the pedigree details.</p>
                        </div>
                        <div className="mx-10">
                            <PedigreeTree onSelect={handleSelected} selected={selected}/>
                        </div>
                        
                    </div>
                    <br/>
                    <div className="center one-fifth-width full-mobile">
                        <p onClick={handleReportClick} className="button"><i className="fas fa-folder-plus mr-10"></i>Generate Report</p>
                    </div>
                </div>

                        
            </MainStructure>
            <Modal isOpen={isOpen}>
                <div className="center">
                    <h2 className="subtitle is-black line-margin-0">Congrats!</h2>
                    <p className="mx-20">Your report is being generated.</p>
                    <p className="mx-20">Check your available report's table to review it.</p>
                    <Link to="/pedigree-analysis" className="button"><i className="fa fa-check mr-10"></i>OK</Link>
                </div>
            </Modal>
            <Modal isOpen={isOpenModels}>
                <div className="">
                    <div class="article">
                        <h2 class="article-title center">Why Generic and Local Models?</h2>
                        <p class="article-text">
                            Generic models have demonstrated significant effectiveness when tested in Europe. 
                            This success is attributed to the richer European horse databases used for training. 
                            However, performance declined noticeably when these models were tested in other regions, 
                            highlighting the need for localized models. The newly released Australia/New Zealand models 
                            now exhibit even greater precision than the original models.
                        </p>

                        <h2 class="article-title">What Do the Models See?</h2>
                        <p class="article-text">In the generic models:</p>
                        <ul class="model-list">
                            <li class="model-item"><strong>Model 1, 2, and 3:</strong> predict the likelihood of horses becoming top performers at different distances.</li>
                            <li class="model-item"><strong>Model 4:</strong> focuses on avoiding low performers.</li>
                            <li class="model-item"><strong>Model 5:</strong> consolidates insights from the other models to optimize performance.</li>
                        </ul>

                        <p class="article-text">The local models for Australia/New Zealand are designed to be more consistent:</p>
                        <ul class="model-list">
                            <li class="model-item"><strong>Model 1:</strong> identifies great performers at any age.</li>
                            <li class="model-item"><strong>Model 2:</strong> identifies great performers at age 2.</li>
                            <li class="model-item"><strong>Model 3:</strong> avoids low performers at age 2.</li>
                            <li class="model-item"><strong>Model 4:</strong> avoids low performers at any age.</li>
                            <li class="model-item"><strong>Model 5:</strong> integrates all models to provide the best potential results.</li>
                        </ul>

                        <p class="article-text">
                            These models concentrate on distances below a mile, reflecting the emphasis on speed in Australian racing. 
                            For longer distances, Model 3 from the generic set is recommended, as it has maintained its precision in Australian tests.
                        </p>

                        <div class="note">
                            <p class="note-text"><strong>Note:</strong> When referring to Models 3 and 4 as avoiding low performers, a high score in these models indicates a lower likelihood of selecting low performers.</p>
                        </div>
                    </div>
                    <div className='center'>
                        <button onClick={() => setIsOpenModels(false)} className="button"><i className="fa fa-check mr-10"></i>OK</button>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default NewPedigree