import React, {useState} from 'react'
import {Link} from 'react-router-dom'

// Components
import Button from '../elements/Button'
import Modal from '../elements/Modal'
import InputField from '../elements/InputField'

// API
import {constantService} from '../../api'


function UpdateModel() {
    const [isOpen, setIsOpen] = useState(false)
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)


    function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)

        const form = document.getElementById('update-model-form')
        const body = new FormData(form)
        constantService.update('ML_MODEL_URL', body)
        .then(response => {
            setIsOpen(true)
            setErrors({})
            setLoading(false)
        })
        .catch(error => {
            setErrors(error)
            console.error("It was not possible to update model. ")
            setLoading(false)
        })
        
    }

    return (
        <React.Fragment>
            <form id="update-model-form" onSubmit={handleSubmit}>
                <h2 className="title is-blue caps">Update ML Model</h2>
                <InputField label="Google drive URL" type="url" name="value" id="input-value" error={errors.value || errors.non_field_error || errors.url}/>
                <Button text="Upload File" class="my-30" icon="fa fa-upload" loading={loading}/>
            </form>
            <Modal isOpen={isOpen}>
                <div className="center">
                    <h2 className="subtitle is-black line-margin-0">Model Uploaded</h2>
                    <p className="mx-20">The new model is currently being uploaded.</p>
                    <br /><br />
                    <Button type="button" text="OK" icon="fa fa-check" className="button" onClick={() => {setIsOpen(false)}}></Button>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default UpdateModel