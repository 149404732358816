import React from 'react'

function InputField(props) {

    if (props.value !== null) {
        return (
            <div className={`left input-field ${props.class}`}>
                <label className="input-field__label" >{props.label}</label>
                <input name={props.name} value={props.value} className="input-field__input" type={props.type} id={props.id} step="any" accept={props.accept} required={props.required} onChange={props.onChange}/>
                <p className="error">{props.error}</p>
            </div>
        )
    } else {
        return (
            <div className={`left input-field ${props.class}`}>
                <label className="input-field__label" >{props.label}</label>
                <input name={props.name} className="input-field__input" type={props.type} id={props.id} step="any" accept={props.accept} required={props.required}/>
                <p className="error">{props.error}</p>
            </div>
        )
    }
}

export default InputField