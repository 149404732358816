import React from 'react'

function Content(props) {

    var className = "content-card"
    if (props.splitted) {
        className = "splitted-card max-screen__bottom"
    }
    return (
        <div className={className}>
            {props.children}
        </div>
    )
}

export default Content