import config from './config'
import { handleResponse } from './handle-response'
import { checkToken, authHeader } from './helpers'

export const subscriptionService = {
    list,
    create,
    cancel,
    upgrade
}

async function list() {

    await checkToken()

    const requestOptions = { method: 'GET', headers: authHeader()}
    return fetch(`${config.apiUrl}/stripe/subscriptions/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

async function create(body) {

    await checkToken()

    const requestOptions = { method: 'POST', headers: authHeader(), body: body}
    return fetch(`${config.apiUrl}/stripe/subscriptions/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

async function cancel(id) {
    await checkToken()

    const requestOptions = { method: "DELETE", headers: authHeader()}
    return fetch(`${config.apiUrl}/stripe/subscriptions/${id}/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

async function upgrade(id, body) {
    await checkToken()

    const requestOptions = { method: "PUT", headers: authHeader(), body: body}
    return fetch(`${config.apiUrl}/stripe/subscriptions/${id}/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}