import React, {useState} from 'react'
import {Link, Redirect} from 'react-router-dom'

// Assets
import Logo from '../../assets/images/logo.png'
import signupImage from '../../assets/images/signup-image.jpg'

// Components
import InputField from '../elements/InputField'
import Button from '../elements/Button'
import SocialMediaButtons from "./SocialMediaButtons";

// API
import {userService} from '../../api'
import TagManager from 'react-gtm-module'

function SignupCard() {
    const [redirect, setRedirect] = useState(false)
    const [errors, setErrors] = useState({})


    function handleSubmit(e) {
        e.preventDefault()
        
        var form_element = document.getElementById('signup-form')
        var form_data = new FormData(form_element)
        
        userService.signup(form_data)
        .then(response => {
            TagManager.dataLayer({
                dataLayer: {
                  event: 'signup',
                  path: '/signup'
                }
              })
            setRedirect(true)
        })
        .catch(error => {
            setErrors({...error})
        })
    }

    if (redirect) {
        return <Redirect to='/login' />
    }

    return (
        <div className="reg-card half-width screen-center full-mobile">
            <div className="flexer flexer-spaced flexer-mobile">
                <div className="flexer-5 center">
                    <img className="one-third-width mt-30 mb-20" src={Logo} alt="GeneUse" />
                    <div className="mx-10"><h1 className="huge-letters is-bold is-black line-margin-0">welcome</h1></div>
                    <p className="subtext line-margin-0">Thank you for registering.</p>
                    <form id="signup-form" className=" mx-20" onSubmit={handleSubmit}>
                        <InputField name="first_name" type="text" label="First Name" error={errors.first_name} />
                        <InputField name="last_name" type="text" label="Last Name" error={errors.last_name} />
                        <InputField name="email" type="email" label="Email" error={errors.email} />
                        <InputField name="password" type="password" label="Password" error={errors.password} />
                        <InputField name="password2" type="password" label="Repeat Password" error={errors.password2 || errors.non_field_errors || errors.detail} />
                        <Button text="Sign up" class="half-width" type="submit" />

                        <SocialMediaButtons type="sign up"/>
                    </form>
                    <p className="subtext is-black mb-30">Already have an account?&nbsp;
                        <a className="link is-gold" href="/login">Log In</a>
                    </p>
                </div>
                <img className="half-width full-mobile" src={signupImage} alt="GeneUse" />
            </div>
        </div>
    )
}

export default SignupCard