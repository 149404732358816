import React, {useState} from 'react'
import { Redirect } from 'react-router-dom';

// Containers
import MainStructure from '../components/containers/MainStructure'
import CreditCardInfo from '../components/MembershipPayment/CreditCardInfo'
import MembershipPlans from '../components/MembershipPayment/MembershipPlans'

// Stripe
import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'

// Config
import config from '../api/config'

function MembershipPayment() {
    const [step, setStep] = useState(1)
    const [flowData, setFlowData] = useState({})

    function nextStep(body, step) {
        setFlowData({...flowData, ...body})
        setStep(step)
    }

    var content 
    if (step === 1) {
        content = <MembershipPlans onUpdate={nextStep}/>
    } else if (step === 2) {
        const stripePromise = loadStripe(config.stripeKey)
        content = <Elements stripe={stripePromise}><CreditCardInfo data={flowData} body={flowData.body} onUpdate={nextStep} type={flowData.type}/></Elements>
    } else if (step === 3) {
        return <Redirect to="/" />
    }

    return (
        <MainStructure>
            {content}
        </MainStructure>
    )
}

export default MembershipPayment