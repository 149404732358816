import React, {useState, useEffect} from 'react'
import { Redirect } from 'react-router'
import {Link} from 'react-router-dom'

// Containers
import MainStructure from '../components/containers/MainStructure'
import Table from '../components/elements/Table'
import Button from '../components/elements/Button'
import Modal from '../components/elements/Modal'

// API
import {membershipReports, userService} from '../api'
import config from '../api/config'

// Assets
import Loader from '../assets/images/loader2.gif'

function Pedigree() {
    const [reports, setReports] = useState([])
    const [redirect, setRedirect] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [reportsLeft, setReportsLeft] = useState(0)

    useEffect(() => {
        userService.details()
        .then(response => {
            setReportsLeft(response.total_reports_limit >= 9999 ? 'Unlimited' : response.total_reports_limit)
        })
        .catch(error => {
            console.error(error)
        })
    }, [])

    useEffect(() => {
        membershipReports.details()
        .then(response => {
            var rows = {}
            for (const report of Object.values(response)) {
                rows[report.id] = report
            }
            setReports(rows)
        })
        .catch(error => {
            console.error(error)
        })
    }, [])
    
    var content
    const isEmpty = reports ? Object.keys(reports)?.length == 0 : true
    if (isEmpty) {
        content = 
            <div className="my-20">
                <p className="line-margin-0">You do not currently have any pedigrees. Click the button to create the first one.</p>
                <p className="line-margin-0">You have <strong>{reportsLeft}</strong> pedigrees left.</p>
            </div>
    } else {
        var headers = <React.Fragment>
                        <th className="text is-black left">Pedigree Name</th>
                        <th className="text is-black left">Created</th>
                        <th className="text is-black left"></th>
                        <th className="text is-black left"></th>
                    </React.Fragment>

        var rows = []
        var rowClass
        var rowNum = 1
        for (const report of Object.values(reports).reverse()) {

            if (rowNum % 2 === 0) {
                rowClass = "even"
            } else {
                rowClass = null
            }

            if(report.report.status === 'completed' && report.report_file) {
                rows.push(<tr className={`table-rows ${rowClass}`} key={report.id}>
                        <td className="text is-black left mx-10">{report.name}</td>
                        <td className="text is-black left">{report.created}</td>
                        <td className="center"><a title={`Combined score: ${report.report.combined_score}`} href={`${report.report_file}`} target="_blank" rel="noreferrer"><i className="is-primary fas fa-download mx-10" /></a></td>
                        <td className="center"><i className="is-gold fa fa-trash mx-10 cursor" onClick={() => {handleDelete(report.id)}} /></td>
                    </tr>)
            }
            else{
                rows.push(<tr className={`table-rows ${rowClass}`} key={report.id}>
                        <td className="text is-black left mx-10">{report.name}</td>
                        <td className="text is-black left">{report.created}</td>
                        <td className="center"><img src={Loader} /></td>
                        <td className="center"><i className="is-gold fa fa-trash mx-10 cursor" onClick={() => {handleDelete(report.id)}} /></td>
                    </tr>)
            }
            rowNum += 1
        }
        
        content = <div className="my-20">
                    <p className="line-margin-0">You have <strong>{reportsLeft}</strong> pedigrees left.</p>
                    <Table className="full-width my-20" headers={headers} rows={rows} />
                </div>
    }

    function handleDelete(report_id) {
        membershipReports.delete_report(report_id)
        .then(response => {
            var newReports = reports
            delete newReports[report_id]
            setReports({...newReports})
        })
        .catch(error => {
            console.error(error)
        })
    }

    if (redirect) {
        if(reportsLeft === 0){
            return <Redirect to="/membership" />
        }
        else{
            return <Redirect to="/new-pedigree" />
        }
    }

    return (
        <MainStructure>
            <div className="center">
                <h2 className="huge-letters is-black">Pedigree <span className="is-bold">Analysis</span></h2>
                <div className="component-card center">
                    <div className="left">
                        <div className="flexer flexer-vcenter flexer-spaced">
                            <p className="subtitle light-weight">Check out your available reports</p>
                            <Button type="button" class="caps full-mobile" text={reportsLeft === 0 ? "Buy Reports":"Create"} icon="fa fa-plus mr-10" onClick={()=>setRedirect(true)} />
                        </div>
                        {content}
                    </div>
                </div>
                <Modal isOpen={isOpen}>
                    <div className="center">
                        <h2 className="subtitle is-black line-margin-0">Oops!</h2>
                        <p className="mx-20">You have reached your reports limit.</p>
                        <br />
                        <p className="button cursor one-third-width center" onClick={()=> {setIsOpen(false)}}>Ok</p>
                    </div>
                </Modal>
            </div>
        </MainStructure>
    )
}

export default Pedigree