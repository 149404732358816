import config from './config'
import { handleResponse } from './handle-response'
import { checkToken, authHeader } from './helpers'

export const userService = {
    details,
    signup,
    list
}

async function details() {

    await checkToken()

    const requestOptions = { method: 'GET', headers: authHeader()}
    return fetch(`${config.apiUrl}/users/details/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

async function list() {

    await checkToken()

    const requestOptions = { method: 'GET', headers: authHeader()}
    return fetch(`${config.apiUrl}/users/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

async function signup(body) {

    const requestOptions = { method: 'POST', body }
    
    return fetch(`${config.apiUrl}/users/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

async function signupFacebook(body) {

    const requestOptions = { method: 'POST', body }
    
    return fetch(`${config.apiUrl}/users/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}