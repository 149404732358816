import config from './config';
import { handleResponse } from './handle-response';
import { checkToken, authHeader } from './helpers'

export const constantService = {
    update
}


async function update(name, body) {

    await checkToken()

    const requestOptions = { method: 'PATCH', headers: authHeader(), body}
    
    return fetch(`${config.apiUrl}/constants/${name}/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

