import React from 'react'

// Components
import SignupCard from '../components/containers/SignupCard'

function Signup() {
    return (
        <React.Fragment>
            <SignupCard />
        </React.Fragment>
    )
}

export default Signup