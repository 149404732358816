import React, {useState} from 'react'

// Components
import Button from '../elements/Button'

// Stripe
import {Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js'

// API
import {paymentMethodService} from '../../api'

function ChangeCard(props) {
    const [loading, setLoading] = useState(false)
    const [billingDetails, setBillingDetails] = useState({phone: "", name: ""})
    const [error, setError] = useState(null)
    
    const CARD_OPTIONS = {
        iconStyle: "solid",
        style: {
          base: {
            iconColor: "#C19C51",
            color: "#272727",
            fontWeight: 500,
            fontFamily: "proxima-nova', sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
              color: "#272727"
            },
            "::placeholder": {
              color: "#737373"
            }
          },
          invalid: {
            iconColor: "red",
            color: "red"
          }
        }
    }

    const stripe = useStripe()
    const elements = useElements()

    if (!stripe || !elements) {
        return ''
    }

    function handleSubmit(e) {
        e.preventDefault()

        setError(null)
        setLoading(true)

        paymentMethodService.create()
        .then(async (response) => {
            const cardElement = elements.getElement(CardElement)

            let { error, setupIntent } = await stripe.confirmCardSetup(response.client_secret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: billingDetails.name
                    }
                }
            })

            if (error) {
                setLoading(false)
                setError(error.message)
            } else {
                setTimeout(function(){ props.onUpdate("view") }, 5000);
            }
        })
        .catch(error => {
            setLoading(false)
            setError(error.non_field_errors)
        })
    }

    return (
        <React.Fragment>
            <p>Please add the information of the card you want to use for future purchases.</p>
            <div className="component-card one-third-width">
                <form className="Form" onSubmit={handleSubmit}>
                    <fieldset className="FormGroup">
                        <div className="FormRow">
                            <label className="FormRowLabel left">Full Name</label>
                            <input className="FormRowInput" type="text" placeholder="John Doe" value={billingDetails.name} onChange={(e) => {setBillingDetails({...billingDetails, name: e.target.value})}} />
                        </div>
                        <div className="FormRow">
                            <label className="FormRowLabel left">Phone</label>
                            <input className="FormRowInput" type="number" placeholder="999-999-9999" value={billingDetails.phone} onChange={(e) => {setBillingDetails({...billingDetails, phone: e.target.value})}} />
                        </div>
                    </fieldset>
                    <fieldset className="FormGroup">
                        <div className="FormRow">
                            <CardElement  options={CARD_OPTIONS} />
                        </div>
                    </fieldset>
                    {error ? <p className="error left mb-20"><i className="fa fa-warning mr-10"></i>{error}</p> : null }
                    <Button type="submit" text="Change Card" class="mt-10 full-width" loading={loading} />
                </form>
            </div>
        </React.Fragment>
    )
}

export default ChangeCard