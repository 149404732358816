import React from 'react'

// Components
import ForgetPasswordCard from '../components/containers/ForgetPasswordCard'

function ForgotPassword() {
    return (
        <React.Fragment>
            <ForgetPasswordCard />
        </React.Fragment>
    )
}

export default ForgotPassword