import React from 'react'

// Components
import Button from './Button'

function Card(props) {

    if (props.small) {
        var price = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.final_price)
        var body = {"body": {
                                "n_reports": props.n_reports
                            },
                    "plan": props.name,
                    "pretty_price": price,
                    "price_id": props.priceId,
                    "type": "standalone"}


        var backgroundsTitle = {
            "1": "basic",
            "2": "premium",
            "3": "power",
        }
        
        let benefitsList = [];
        if(props.is_premium){
            benefitsList.push(
                <>
                    <li className="text is-blue is-light-weight benefit include">
                        <div>
                            <i className="fa fa-check"></i>
                            Equivalent to <strong className="include-cant">{props.n_reports*100}</strong> standalone reports
                        </div>
                    </li>
                    <hr/>
                </>
            ) 
        }else{
            benefitsList.push(
                <>
                    <li className="text is-blue is-light-weight benefit include">
                        <div>
                            <i className="fa fa-check"></i>
                            <strong className="include-cant">{props.n_reports}</strong> standalone reports
                        </div>
                    </li>
                    <hr/>
                </>
            )    
        }

        var cardDetails = <ul className="benefits-list"> { benefitsList } </ul>


        var bgClassTitleStandalone = `is-${backgroundsTitle[props.pnum]}-plan`;
        var bgClassButtonStandalone = `is-button-${backgroundsTitle[props.pnum]}-plan`;
        return (
            <div className="card flexer-card">
                <div className={`${bgClassTitleStandalone} title-plan`}>
                    <h4 className="subtitle is-white caps light-bold">{props.name}</h4>
                </div> 
                <p className="subtitle price is-black caps line-margin-0">
                    <strong >{props.final_price}</strong> USD
                </p>
                <div className="includes-title">
                    <hr/>
                    <h6>INCLUDES</h6>
                </div>
                <div className="container-benefits-standalone">
                    {cardDetails}
                </div>
                <div className={`${bgClassButtonStandalone} container-button flexer-2`}>
                    <div className="px-30 py-30">
                    <Button type="button" class="full-width" text="Select" onClick={() => {props.onUpdate(body, 2)}}/>
                    </div>
                </div> 
            </div>
        )
    } else {
        
        var price = new Intl.NumberFormat('en-US', { style: 'currency', currency: props.currency }).format(props.price)
        var body = {"plan": props.membershipName,
                    "nickname": props.name,
                    "description": props.description,
                    "price": props.price,
                    "price_id": props.priceId,
                    "currency": props.currency,
                    "pretty_price": price,
                    "frequency": props.freq,
                    "type": "subscription"}

        var cardClass = "card flexer-card"
        var cardButton = <Button type="button" class=" full-width" onClick={() => {props.onUpdate(body, 2)}} text="Select" />

        var benefitReports = props.features.standard;
        var benefitPremium = props.features.premium;
        var benefitFaceToFace = props.features["one-to-one"];

        let benefitsList = [];

        benefitsList.push(
            <>
                <li className="text is-blue is-light-weight benefit include">
                    <div>
                        <i className="fa fa-check"></i>
                        <strong className="include-cant">{benefitReports} </strong> reports per month
                    </div>
                </li>
                <hr/>
            </>
        )

        if(benefitPremium){
            benefitsList.push(
                <>
                    <li className="text is-blue is-light-weight benefit include">
                        <div>
                            <i className="fa fa-check"></i>
                            <strong className="include-cant">{benefitPremium}</strong> premium report per year (up to 100 horses)
                        </div>
                    </li>
                    <hr/>
                </>
            ) 
        }else{
            benefitsList.push(
                <>
                    <li className="text is-blue is-light-weight benefit not-include">
                        <div>
                            <i className="fa fa-close"></i>
                            <strong>0</strong> premium report per year
                        </div>
                    </li>
                    <hr/>
                </>
            ) 
        }

        if(benefitFaceToFace){
            benefitsList.push(
                <>
                    <li className="text is-blue is-light-weight benefit include">
                        <div>
                            <i className="fa fa-check"></i>
                            <strong className="include-cant">{benefitFaceToFace}</strong> face to face call per year. How to maximize tool use
                        </div>
                    </li>
                    <hr/>
                </>
            ) 
        }else{
            benefitsList.push(
                <>
                    <li className="text is-blue is-light-weight benefit not-include">
                        <div>
                            <i className="fa fa-close"></i>
                            <strong>0</strong> face to face call per year
                        </div>
                    </li>
                    <hr/>
                </>
            ) 
        }

        var cardDetails = <ul className="benefits-list"> { benefitsList } </ul>
        if (props.type === "current") {
            cardClass = "card flexer-card plans-card-active"
            cardButton = <div className="current-button">Current Plan</div>
        } else if (props.type === "downgrade") {
            cardClass = "card flexer-card plans-card-active"
            cardButton = null
            cardDetails = null
        } else if (props.type === "upgrade") {
            cardButton = <Button type="button" class=" full-width" text="Select" onClick={props.onUpdate} />
        }

        var basicName = props.membershipName.split(" ")[0].toLowerCase();
        var bgClassTitlePlan = `is-${basicName}-plan`;
        var bgClassButtonPlan = `is-button-${basicName}-plan`;

        return (
            <div className={cardClass}>
                <div className={`${bgClassTitlePlan} title-plan`}>
                    <h4 className="subtitle is-white caps light-bold">{props.membershipName}</h4>
                </div>       
                <div className="flexer-2 mx-30">
                    <p className="subtitle price is-black caps line-margin-0">
                        <strong >{props.price}</strong> USD
                    </p>
                    <p className="subtext is-black line-margin-0">{`per ${props.freq} with discount`}</p>
                    <div className="includes-title">
                        <hr/>
                        <h6>INCLUDES</h6>
                    </div>                    
                    <div className="container-benefits">
                        {cardDetails}
                    </div>
                </div>    
                <div className={`${bgClassButtonPlan} container-button flexer-2`}>
                    <div className="px-30 py-30">
                        {cardButton}
                    </div>
                </div>     
            </div>
        )
    }

}

export default Card