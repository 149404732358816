import React, {useState, useEffect} from 'react'
import GoogleLogin from "react-google-login";
import {Redirect} from "react-router-dom";

//API
import { loadGoogleScript } from "../../api/GoogleLogin";
import {authenticationService} from "../../api";
import config from '../../api/config';
import TagManager from 'react-gtm-module';

function GoogleLoginButton(props) {

    const [gapi, setGapi] = useState();
    const [googleAuth, setGoogleAuth] = useState();
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        window.onload = () => {
            const _gapi = window.google;
            _gapi.accounts.id.initialize({
                client_id: config.googleAppId,
                callback: onSuccess
            });
            renderSigninButton(_gapi);
            _gapi.accounts.id.prompt();
        }
        loadGoogleScript();
    }, []);

    const textType = () => {
        let str = props.type;
        let lowerType = str.toLowerCase();
        return str.charAt(0).toUpperCase() + lowerType.slice(1);
    }

    if (redirect) {
        return <Redirect to="/"/>
    }

    const onSuccess = (googleUser) => {
        if (googleUser?.credential) {

            let payload = {
                "access_token": googleUser?.credential,
            }

            authenticationService.loginGoogle(payload)
                .then(response => {
                    if (props.type === 'sign up')  {
                        console.log(props.type)
                        TagManager.dataLayer({
                            dataLayer: {
                              event: 'google-signup',
                            }
                          })
                        }
                    setRedirect(true)
                })
                .catch(error => {
                    props.onErrorRequest(error.non_field_errors);
                })
        } else {
            props.onErrorRequest(`An error occurred in the ${textType()} with Google`);
        }
    };

    const renderSigninButton = (_gapi) => {
        _gapi.accounts.id.renderButton(
            document.getElementById("buttonDiv"),
            {
                theme: "outline",
                size: "large",
                width: "80%",
                logo_alignment: "center",
                locale: "en-US",
                text: `${textType()} with Google`

            }  // customization attributes
        );
    }

    return (
        <>
            <div id="buttonDiv" className="custom-social-button google-login-button"></div>
        </>
    )

}

export default GoogleLoginButton