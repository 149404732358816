import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

// Containers
import MainStructure from '../components/containers/MainStructure'

// Components
import Table from '../components/elements/Table'
import Button from '../components/elements/Button'
import CreditCardInfo from '../components/MembershipPayment/CreditCardInfo'

// Assets
import Loader from '../assets/images/loader2.gif'

// API
import {premiumReports, userService} from '../api'

// Stripe
import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'

// Config
import config from '../api/config'

function PremiumPlan() {
    const [premiums, setPremiums] = useState([])
    const [requestLeft, setRequestLeft] = useState(0)
    const [payment, setPayment] = useState(null)

    useEffect(() => {
        premiumReports.details()
        .then(response => {
            setPremiums(response)
        })
        .catch(error => {
            console.error(error)
        })
    }, [])

    useEffect(() => {
        userService.details()
        .then(response => {
            if (response.total_premium_reports_limit > 0) {
                setRequestLeft(response.total_premium_reports_limit)
            }
        })
        .catch(error => {
            console.error(error)
        })
    }, [])

    var headers = <React.Fragment>
                    <th className="text is-black left">Request Name</th>
                    <th className="text is-black left">Created</th>
                    <th className="text is-black center">Status</th>
                </React.Fragment>

    var content = []
    var rowClass
    var rowNum = 1
    for (const premium of Object.values(premiums)) {

        if (rowNum % 2 === 0) {
            rowClass = "even"
        } else {
            rowClass = null
        }

        let status
        if (premium.report_file && (premium.paid || premium.price === 0)){
            status = <a href={`${premium.report_file}`} target="_blank" rel="noreferrer"><i className="is-primary fas fa-download mx-10" /></a>
        }
        else if (premium.ready_for_payment && !premium.paid && premium.price > 0){
            status = <Button text="Pay" onClick={()=>{setPayment(premium)}} />
        }
        else if ((premium.paid || premium.price === 0) && !premium.report_file){
            status = <img src={Loader} style={{display: 'inline-block'}} className="" />
        }
        else {
            status = 'Requested'
        }

        content.push(
            <tr className={`table-rows ${rowClass}`} key={premium.id}>
                <td className="text is-black left">{premium.name}</td>
                <td className="text is-black left">{premium.created}</td>
                <td className="text is-black center">{status}</td>
            </tr>
        )

        rowNum += 1

    }

    var rows = <React.Fragment>{content}</React.Fragment>

    var requestMessage =
        <React.Fragment>
            <p className="line-margin-0">You have <strong>{requestLeft}</strong> premium request left.</p>
        </React.Fragment>

    if (payment) {

        var price = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(payment.price)
        var data = {plan: payment.name, nickname: 'premium report', description: 'You pay the premium report once and you have access forever to it.', pretty_price: price}
        var body = {premium: payment.id}
        const stripePromise = loadStripe(config.stripeKey)
        var card_content = <Elements stripe={stripePromise}><CreditCardInfo data={data} type="payment" body={body} onUpdate={()=>{setPayment(null)}}/></Elements>

        return (
            <MainStructure>
                {card_content}
            </MainStructure>
        )
    }

    return (
        <MainStructure>
             <div className="center">
                <h2 className="huge-letters is-black">Premium <span className="is-bold">Request</span></h2>
                <div className="card left">
                    <div className="flexer flexer-vcenter flexer-spaced">
                        <p className="subtitle light-weight">Check out your current premium reports</p>
                        <Link to="new-premium" className="button caps"><i className="fa fa-plus mr-10"></i>New Premium Request</Link>
                    </div>
                    <Table className="full-width my-20" headers={headers} rows={rows} />
                </div>
                <p>Check out your current premium request.</p>
                {requestMessage}
                <Table className="two-third-width" headers={headers} rows={rows} />
            </div>
        </MainStructure>
    )
}

export default PremiumPlan