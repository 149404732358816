import React, {useState, useEffect} from 'react'

// API
import {plansService, subscriptionService} from '../../api'

// Components
import Card from '../elements/Card'
import Modal from '../elements/Modal'
import Button from '../elements/Button'

function UpdatePlan(props) {
    const [plans, setPlans] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [newPrice, setNewPrice] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        plansService.list()
        .then(response => {
            setPlans(response)
        })
        .catch(error => {
            console.error(error)
        })
    }, [])

    function handleUpgrade(price) {
        setLoading(true)

        var formData = new FormData
        formData.append("price_id", price)

        subscriptionService.upgrade(props.data.id, formData)
        .then(response => {
            setTimeout(function(){ props.onUpdate("view") }, 5000);
        })
        .catch(error => {
            setLoading(false)
            setError(error.non_field_errors)
        })
    }

    function prepareUpgrade(price) {
        setIsOpen(true)
        setNewPrice(price)
        setError(null)
    }

    if (!plans || !props.data) {
        return (
            <React.Fragment></React.Fragment>
        )
    }

    var content = []
    var upgrade = false
    for (const plan of Object.values(plans)) {

        if (plan.id === props.data.items.data[0].plan.id) {
            content.push(
                <Card name={plan.nickname}
                    priceId={plan.id}
                    membershipName={plan.product.name} 
                    description={plan.metadata.description}
                    price={plan.unit_amount} 
                    freq={plan.recurring.interval} 
                    currency={plan.currency} 
                    type="current"
                />
            )
            upgrade = true
        } else if (upgrade) {
            content.push(
                <Card name={plan.nickname}
                    priceId={plan.id}
                    membershipName={plan.product.name} 
                    description={plan.metadata.description}
                    price={plan.unit_amount} 
                    freq={plan.recurring.interval} 
                    currency={plan.currency} 
                    onUpdate={() => {prepareUpgrade(plan.id)}}
                    type="upgrade"
                />
            )
        } else {
            content.push(
                <Card name={plan.nickname}
                    priceId={plan.id}
                    membershipName={plan.product.name} 
                    description={plan.metadata.description}
                    price={plan.unit_amount} 
                    freq={plan.recurring.interval} 
                    currency={plan.currency} 
                    type="downgrade"
                />
            )
        }
    }

    return (
        <React.Fragment>
            <div className="flexer center">
                {content}
            </div>
            <Modal isOpen={isOpen}>
                <div className="center">
                    <h2 className="subtitle is-black line-margin-0">Upgrade subscription</h2>
                    <p className="mx-20">Are you sure you want to upgrade your subscription?</p>
                    <p className="mx-20">You will be charged the difference between your current subscription price and the new one.</p>
                    {error ? <p className="error">{error}</p> : <div><br /><br /></div>}
                    <div className="flexer center">
                        {loading ? null : <Button type="button" text="No" class="mx-10" icon="fa fa-close" onClick={() => {setIsOpen(false)}} />}
                        <Button type="button" text="Yes" class="mx-10" icon="fa fa-check" onClick={() => handleUpgrade(newPrice)} loading={loading} />
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default UpdatePlan