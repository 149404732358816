import React from 'react'

function Dropdown(props) {
    let options = []
    for (const [value, option] of Object.entries(props.options)){
        options.push(<option value={value}>{option}</option>)
    }

    return (
        <div className={`left input-field ${props.class}`}>
            <label className="input-field__label" >{props.label}</label>
            <select name={props.name} className="input-field__input" id={props.id} onChange={props.onChange}>
                {options}
            </select>
            <p className="error">{props.error}</p>
        </div>
    )
}

export default Dropdown