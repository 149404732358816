import {authenticationService} from './'
import jwt_decode from 'jwt-decode'

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.access || currentUser && currentUser.access_token) {
        return { Authorization: `Bearer ${currentUser.access || currentUser.access_token}` }
    } else {
        return {}
    }
}

export async function checkToken() {

    const tokens = authenticationService.currentUserValue
    const expiry = tokens['expiry']
    if (!expiry){
        authenticationService.logout()
    }

    const now = Date.now()/1000
    if (now > expiry) {
        // Check Refresh
        const refresh = jwt_decode(tokens['refresh'])
        if (now > refresh['exp']) {
            console.log("Logging out")
            await authenticationService.logout()
        }
        else {
            await authenticationService.refreshToken()
        }
    }
}