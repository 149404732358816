import React, {useState} from 'react'
import {Link, Redirect} from 'react-router-dom'

// Assets
import Logo from '../../assets/images/logo.png'
import signupImage from '../../assets/images/signup-image.jpg'

// Components
import InputField from '../elements/InputField'
import Button from '../elements/Button'

// API
import {authenticationService} from '../../api'

function ForgetPasswordCard() {
    const [completed, setCompleted] = useState(false)
    const [errors, setErrors] = useState({})

    function handleSubmit(e) {
        e.preventDefault()

        var form_element = document.getElementById('forgot-password-form')
        var form_data = new FormData(form_element)

        authenticationService.forgotPassword(form_data)
        .then(response => {
            setCompleted(true)
        })
        .catch(error => {
            setErrors({...error})
        })
    }

    let form_section = (
        <form id="forgot-password-form" className="my-20 mx-20" onSubmit={handleSubmit}>
            <InputField name="email" type="email" label="Email" error={errors.email || errors.non_field_errors} />
            <Button text="Request email" class="two-third-width mt-30" type="submit" />
        </form>
    )

    if (completed){
        form_section = <p>If the email address is registered, an email was sent there.</p>
    }
    return (
        <div className="reg-card half-width screen-center full-mobile">
            <div className="flexer flexer-spaced flexer-vcenter flexer-mobile">
                <div className="flexer-5 center">
                    <img className="one-third-width mt-30 mb-20" src={Logo} alt="GeneUse" />
                    <div className="mx-10"><h1 className="huge-letters is-black line-margin-0">forgot <span className="is-bold">password</span></h1></div>
                    <p className="subtext px-30">Give us your email address and we will send you an email with instructions.</p>
                    {form_section}
                    <p className="subtext is-black mb-30">Don't have an account?&nbsp;
                        <Link className="link is-gold" to="/signup">Sign Up</Link>
                    </p>
                </div>
                <img className="half-width full-mobile" src={signupImage} alt="GeneUse" />
            </div>
        </div>
    )
}

export default ForgetPasswordCard