import config from './config'
import { handleResponse } from './handle-response'
import { checkToken, authHeader } from './helpers'

export const plansService = {
    list,
    listByReportNumber
}

async function list() {

    await checkToken()

    const requestOptions = { method: 'GET', headers: authHeader()}
    return fetch(`${config.apiUrl}/stripe/plans/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

async function listByReportNumber() {

    await checkToken()

    const requestOptions = { method: 'GET', headers: authHeader()}
    return fetch(`${config.apiUrl}/plans-by-reports/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}
