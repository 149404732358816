import React, {useState} from 'react';
import FacebookLogin from 'react-facebook-login';
import TagManager from 'react-gtm-module';
import {Redirect} from "react-router-dom";

//API
import {authenticationService} from "../../api";
import config from "../../api/config";

function FacebookLoginButton(props) {
    const [redirect, setRedirect] = useState(false)

    const textType = () => {
        let str = props.type;
        let lowerType = str.toLowerCase();
        return str.charAt(0).toUpperCase() + lowerType.slice(1);
    }

    const responseFacebook = (response) => {
        if (response?.accessToken) {
            
            let payload = {
                "access_token": response?.accessToken,
            }
            
            authenticationService.loginFacebook(payload)
            .then(response => {
                if (props.type === 'sign up')  {
                    console.log(props.type)
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'facebook-signup',
                        }
                    })
                }
                setRedirect(true)
                })
                .catch(error => {
                    props.onErrorRequest(error.non_field_errors);
                })
        } else {
            props.onErrorRequest(`An error occurred in the ${textType()} with facebook`);
        }
    }

    const failedFacebook = (error) => {
        props.onErrorRequest(`An error occurred in the ${textType()} with facebook`);
    }

    if (redirect) {
        return <Redirect to="/"/>
    }

    return (
        <FacebookLogin
            appId={config.facebookAppId}
            textButton={`${textType()} with Facebook`}
            autoLoad={false}
            fields="name,email,picture"
            scope="public_profile,email"
            callback={responseFacebook}
            onFailure={failedFacebook}
            icon="fa-facebook"
        />
    )

}

export default FacebookLoginButton