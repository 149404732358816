import React from 'react'

// Components
import ChangePasswordCard from '../components/containers/ChangePasswordCard'

function ChangePassword() {
    return (
        <React.Fragment>
            <ChangePasswordCard />
        </React.Fragment>
    )
}

export default ChangePassword