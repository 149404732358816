const config = {
    //apiUrl: 'http://localhost:8001/api/v1'
    //'http://18.223.129.183/api/v1',
    //apiUrl: process.env.API_URL ?? 'http://18.223.129.183/api/v1',
    // apiUrl: 'https://app.gene-use.co/api/v1',
    apiUrl: 'https://app.geneuse.ai/api/v1',
    stripeKey: process.env.REACT_APP_STRIPE_API_KEY ?? 'pk_live_51IvjtcLkiUVp7slc11nUACbB4C83qhIsh65VtTepQLf2USLj0aXB0mEPIbTuDgKdGHFaL6WJ7WmZIhCCRjJXNLMR00oUT7JDKk',
    facebookAppId: '3049866175344006',
    googleAppId: '290594843035-6cig2gr160vujpao8a17djnpcj52ak6i.apps.googleusercontent.com',
    gtmId: 'GTM-NSXBJVQ'
}
export default config
