import React, {useState, useEffect} from 'react'

// Components
import FacebookLoginButton from '../elements/FacebookLoginButton'
import GoogleLoginButton from '../elements/GoogleLoginButton'

function SocialMediaButtons(props) {
    const [errors, setErrors] = useState("")
    const [googleButtonShow, setGoogleButtonShow] = useState(0)
    const [googleButton, setGoogleButton] = useState(null)
    const [origin, setOrigin] = useState(null)
    useEffect(() => {
        if (!googleButton)
            setGoogleButton(<GoogleLoginButton type={props.type} onErrorRequest={handleError}/>);
    });

    const currentOrigin = window.location.pathname;
    if(origin !== currentOrigin){
        setOrigin(currentOrigin);
        setGoogleButton(null);
        setGoogleButtonShow(0);
    }

    if(googleButtonShow === 0) setGoogleButtonShow(1);


    const handleError = (error) => {
        if(error){
            setErrors(error);
        }
    }

    return (
        <div className="social-buttons-container">
            <div className="rule-with-text social-buttons-separator">OR</div>
            {googleButton}
            <FacebookLoginButton type={props.type} onErrorRequest={handleError}/>
            <p className="error">{errors}</p>
        </div>
    )
}

export default SocialMediaButtons