import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

// Assets
import Logo from '../../assets/images/logo.png'

//API
import {authenticationService, userService} from '../../api'

function Header() {

    const [user, setUser] = useState({})

    function handleLogout(e) {
        authenticationService.logout()
        .then(response => {
            console.log(response)
        })
        .catch(error => {
            console.error(error)
        })
    }

    useEffect(() => {
        userService.details()
        .then(response => {
            setUser(response)
            console.log(response)
        })
        .catch(error => {
            console.error(error)
        })
    }, [])

    return (
        <React.Fragment>
            <div className="flexer flexer-spaced flexer-vcenter">
                <div className="my-5 mx-20 center-mobile">
                    <Link to="/"><img className="logo" src={Logo} alt="logo" /></Link>
                </div>
                <div className="flexer flexer-spaced flexer-vcenter center-mobile mx-20">
                    <p className="text is-blue">Hi,</p>
                    <p className="text is-blue is-bold mx-10">{`${user.first_name} ${user.last_name}`}</p>
                    <i className="fa fa-sign-out is-gold cursor" onClick={handleLogout} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Header