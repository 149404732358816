import config from './config'
import { handleResponse } from './handle-response'
import { checkToken, authHeader } from './helpers'

export const catalogueReports = {
    details,
    create,
    validate,
    cancel_validation
}

async function details() {

    await checkToken()

    const requestOptions = { method: 'GET', headers: authHeader()}
    return fetch(`${config.apiUrl}/catalogue-reports/details/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

async function create(body) {
    await checkToken()

    const requestOptions = { method: 'POST', headers: authHeader(), body: body}
    return fetch(`${config.apiUrl}/catalogue-reports/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

async function validate(body) {
    await checkToken()

    const requestOptions = { method: 'POST', headers: authHeader(), body: body}
    return fetch(`${config.apiUrl}/catalogue-reports/validate/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

async function cancel_validation(body) {
    await checkToken()

    const requestOptions = { method: 'POST', headers: authHeader(), body: body}
    return fetch(`${config.apiUrl}/catalogue-reports/cancel_validation/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}