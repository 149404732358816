import React from 'react'
import {Link} from 'react-router-dom'

// Components
import SidebarItem from '../elements/SidebarItem'

//API
import { authenticationService } from '../../api';

function Sidebar() {
    var path = window.location.pathname

    const currentUser = authenticationService.currentUserValue

    return (
        <React.Fragment>
            <div className="sidebar">
                <Link className="full-width" to="/membership"><SidebarItem item="1" icon="fa fa-user" text="MEMBERSHIP" active={path === "/membership" ? true : false} /></Link>
                <Link className="full-width" to="/pedigree-analysis"><SidebarItem item="2" icon="fas fa-stream" text="PEDIGREE ANALYSIS" active={path === "/pedigree-analysis" ? true : false} /></Link>
                <Link className="full-width" to="/catalogues"><SidebarItem item="3" icon="fa fa-trophy" text="CATALOGUES" active={path === "/catalogues" ? true : false} /></Link>
                <Link className="full-width" to="/premium"><SidebarItem item="3" icon="fa fa-file-alt" text="PREMIUM REQUESTS" active={path === "/premium" ? true : false} /></Link>
                {currentUser.user.is_staff? <Link className="full-width" to="/functionalities-for-admin"><SidebarItem item="5" icon="fa fa-cog" text="ADMIN FUNCTIONALITIES" active={path === "/functionalities-for-admin" ? true : false} /></Link> : null }
            </div>
        </React.Fragment>
    )
}

export default Sidebar