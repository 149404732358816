import config from './config'
import { handleResponse } from './handle-response'
import { checkToken, authHeader } from './helpers'

export const premiumReports = {
    create,
    details
}


async function create(body) {

    await checkToken()

    const requestOptions = { method: 'POST', headers: authHeader(), body}
    return fetch(`${config.apiUrl}/premium-reports/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

async function details() {

    await checkToken()

    const requestOptions = { method: 'GET', headers: authHeader()}
    return fetch(`${config.apiUrl}/premium-reports/details/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}
