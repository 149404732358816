import React, {useState, useEffect} from 'react'

// Components
import MainStructure from '../components/containers/MainStructure'
import CreditCardInfo from '../components/MembershipPayment/CreditCardInfo'
import CatalogueCard from '../components/elements/CatalogueCard'

// API
import {catalogueReports} from '../api'
import { authenticationService } from '../api'

// Assets
import Loader from '../assets/images/loader2.gif'
import CatalogueTitle from '../assets/images/catalogue-title.jpg'

// Stripe
import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'

// Config
import config from '../api/config'

function Catalogues() {
    const [catalogues, setCatalogues] = useState([])
    const [payment, setPayment] = useState(null)
    const [refresh, setRefresh] = useState(0)

    const currentUser = authenticationService.currentUserValue

    useEffect(() => {
        catalogueReports.details()
        .then(response => {
            response.paid = response.paid.map(obj=> ({ ...obj, paid: true }))
            response.unpaid = response.unpaid.map(obj=> ({ ...obj, paid: false }))
            setCatalogues([...response.paid, ...response.unpaid])
        })
        .catch(error => {
            console.error(error)
        })
    }, [refresh])

    var row
    var rows = []
    var temporary, j
    var chunk = 4
    for (var i = 0, j = catalogues.length; i < j; i += chunk) {
        temporary = catalogues.slice(i, i + chunk)

        var column = []
        for (const temp of temporary) {
            column.push(
                <CatalogueCard data={temp} get={setPayment} />
            )
            row = <div className="flexer flexer-mobile">{column}</div>
        }
        
        rows.push(row)
    }

    function GetCatalogue(data) {
        setPayment(data)
    }

    if (payment) {
        
        var price = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(payment.final_price)
        var data = {plan: payment.name, nickname: 'catalogue', description: 'You pay the catalogue once and you have access forever to it.', pretty_price: price}
        var body = {catalogue: payment.id}
        const stripePromise = loadStripe(config.stripeKey)
        var card_content = <Elements stripe={stripePromise}><CreditCardInfo data={data} type="payment" body={body} onUpdate={()=>{setPayment(null); setRefresh(refresh + 1)}}/></Elements>

        return (
            <MainStructure>
                {card_content}
            </MainStructure>
        )
    }
    else {
        return (
            <MainStructure>
                <div className="banner-mobile is-relative">
                    <img className="full-width" src={CatalogueTitle} />
                    <div className="textover-topleft mx-30 my-20">
                        <h2 className="title is-white line-margin-0">Catalogues</h2>
                        <p className="text is-white">Check out our current catalogues</p>
                    </div>
                </div>
                {rows}
            </MainStructure>
        )
    }
}

export default Catalogues