import React from 'react'

function TreeItem(props) {

    if (props.editable) {
        return (
            <div className="tree__row flexer flexer-vcenter">
                <input className="tree__input" value={props.value} onChange={props.editable} />
            </div>
        )
    }

    return (
        <div className="tree__row flexer flexer-vcenter">
            <input className="tree__input" value={props.value} />
        </div>
    )
    
}

export default TreeItem