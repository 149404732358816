import React from 'react'
import {Link} from 'react-router-dom'

// Components
import Button from '../elements/Button'

// Assets
import MembershipImage from '../../assets/images/membership-image.jpg'
import CatalogueImage from '../../assets/images/catalogue-image.jpg'
import PedigreeImage from '../../assets/images/pedigree-image.jpg'
import PremiumImage from '../../assets/images/premium-image.jpg'

function HomeContent() {
    return (
        <React.Fragment>
            <div className="banner-mobile is-relative">
                <img className="full-width" src={MembershipImage} />
                <div className="textover-topleft mx-30 my-30">
                    <h2 className="huge-letters is-white line-margin-0 is-light-weight"><span className="is-bold">Membership</span> plan</h2>
                    <p className="text is-white">Join today our membership plan to start analyzing pedigrees</p>
                    { false ? <p className="text is-warning"><i className="fa fa-warning" /> You do not have a plan at the moment.</p> : null }
                </div>
                <div className="textover-bottomleft mx-30 my-40">
                    <Link to="/membership" className="button mb-40">Join Today</Link>
                </div>
            </div>
            <div className="flexer flexer-mobile flexer-spaced">
                <div className="is-relative home-card">
                    <img className="full-width filter-mobile" src={PedigreeImage} />
                    <div className="textover-bottomleft mx-30">
                        <h2 className="huge-letters is-white line-margin-0 is-bold">Pedigree analysis</h2>
                        <p className="text is-white">Start checking your pedigrees</p>
                        <Link to="/pedigree-analysis" className="button mb-40">Start</Link>
                    </div>
                </div>
                <div className="is-relative home-card">
                    <img className="full-width filter-mobile" src={CatalogueImage} />
                    <div className="textover-bottomleft mx-30">
                        <h2 className="huge-letters is-white line-margin-0 is-bold">Catalogues</h2>
                        <p className="text is-white">Check our already analyzed auction catalogues!</p>
                        <Link to="/catalogues" className="button mb-40">Get yours</Link>
                    </div>
                </div>
                <div className="is-relative home-card">
                    <img className="full-width filter-mobile" src={PremiumImage} />
                    <div className="textover-bottomleft mx-30">
                        <h2 className="huge-letters is-white line-margin-0 is-bold">Premium <span className="is-light-weight">requests</span></h2>
                        <p className="text is-white">Membership plan</p>
                        <Link to="/premium" className="button mb-40">Request</Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default HomeContent