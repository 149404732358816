import config from './config'
import { handleResponse } from './handle-response'
import { checkToken, authHeader } from './helpers'

export const paymentService = {
    create,
}

async function create(body) {

    await checkToken()

    const requestOptions = { method: 'POST', headers: {...authHeader(), 'Content-type': 'application/json'}, body: JSON.stringify(body) }
    return fetch(`${config.apiUrl}/stripe/payments/`, requestOptions)
    .then(handleResponse)
    .then(user => {
        return user
    })
}