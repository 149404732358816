import React, {useState, useEffect} from 'react'

// Components
import MainStructure from '../components/containers/MainStructure'
import ChangeCard from '../components/ManageSubscription/ChangeCard'
import ViewSubscription from '../components/ManageSubscription/ViewSubscription'
import UpdatePlan from '../components/ManageSubscription/UpdatePlan'
import Modal from '../components/elements/Modal'
import Button from '../components/elements/Button'

// API
import {subscriptionService} from '../api/subscription.service'

// Stripe
import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'

// Config
import config from '../api/config'

function ManageSubscription() {
    const [stage, setStage] = useState("view")
    const [data, setData] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [refresh, setRefresh] = useState(0)

    function nextStep(step) {
        setStage(step)
        if (step === "view") {
            setRefresh(refresh + 1)
        }
    }

    function handleCancel() {
        subscriptionService.cancel(data.id)
        .then(response => {
            setIsOpen(false)
        })
        .catch (error => {
            console.error(error)
        })
    }

    useEffect(() => {
        subscriptionService.list()
        .then(response => {
            setData(response[0])
        })
        .catch(error => {
            console.error(error)
        })
    }, [refresh])

    var content
    if (stage === "view") {
        content = <ViewSubscription data={data} onUpdate={nextStep} />
    } else if (stage === "change") {
        const stripePromise = loadStripe(config.stripeKey)
        content = <Elements stripe={stripePromise}><ChangeCard onUpdate={nextStep} /></Elements>
    } else if (stage === "update") {
        content = <UpdatePlan data={data} onUpdate={nextStep} />
    }

    return (
        <MainStructure>
            <div className="left">
                <div className="flexer flexer-spaced flexer-vcenter">
                    <h2 className="title is-blue caps">My Subscription</h2>
                    {stage === "view" ? <p className="button" onClick={() => {setIsOpen(true)}}><i className="fa fa-close mr-10"></i>Cancel Subscription</p> : null}
                </div>
                {content}
            </div>
            <Modal isOpen={isOpen}>
                <div className="center">
                    <h2 className="subtitle is-black line-margin-0">Cancel subscription</h2>
                    <p className="mx-20">Are you sure you want to cancel your subscription?</p>
                    <p className="mx-20">If you cancel it, your subscription will become inactive at the end of your currently paid period.</p>
                    <br /><br />
                    <div className="flexer center">
                        <Button type="button" text="No" class="mx-10" icon="fa fa-close" onClick={() => {setIsOpen(false)}} />
                        <Button type="button" text="Yes" class="mx-10" icon="fa fa-check" onClick={handleCancel} />
                    </div>
                </div>
            </Modal>
        </MainStructure>
    )
}

export default ManageSubscription