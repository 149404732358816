import React from 'react'

// Components
import MainStructure from '../components/containers/MainStructure'
import HomeContent from '../components/containers/HomeContent'

function Home() {
    return (
        <React.Fragment>
            <MainStructure>
                <HomeContent />
            </MainStructure>
        </React.Fragment>
    )
}

export default Home