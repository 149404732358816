import React from 'react'

function TreeLines() {
    return (
        <React.Fragment>
            <div className="tree__line" data-num="1"></div>
            <div className="tree__line" data-num="2"></div>
            <div className="tree__line" data-num="3"></div>
            <div className="tree__line" data-num="4"></div>
            <div className="tree__line" data-num="5"></div>
            <div className="tree__line" data-num="6"></div>
            <div className="tree__line" data-num="7"></div>
            <div className="tree__line" data-num="8"></div>
            <div className="tree__line" data-num="9"></div>
            <div className="tree__line" data-num="10"></div>
            <div className="tree__line" data-num="11"></div>
            <div className="tree__line" data-num="12"></div>
            <div className="tree__line" data-num="13"></div>
            <div className="tree__line" data-num="14"></div>
            <div className="tree__line" data-num="15"></div>
        </React.Fragment>
    )
}

export default TreeLines