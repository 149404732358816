import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { authenticationService } from '../api';

const PrivateRoute = ({ component: Component, admin, ...rest }) => (
    <Route {...rest} render={props => {

        const currentUser = authenticationService.currentUserValue;
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        if (admin === true && currentUser.user.is_staff === false) {
            return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }
        // authorised so return component
        return <Component {...props} />
    }} />
)

export default PrivateRoute