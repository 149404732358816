import React from 'react'

// Components
import Button from './Button'

// Assets
import CatalogueImage from '../../assets/images/catalogue1.jpg'
import Loader from '../../assets/images/loader2.gif'

function CatalogueCard(props) {

    let catalogue_image = props.data.image_file ? props.data.image_file : CatalogueImage
    // let catalogue_image = CatalogueImage
    
    let button
    if (props.data.paid) {
        if (props.data.report_file) {
            button = <a style={{display: 'inline-block'}} className="button" target="_blank" href={props.data.report_file}>View</a>
        } else {
            button = <img src={Loader} style={{display: 'inline-block'}} className="" />
        }
    } else {
        button = <Button text="Get" onClick={() => {props.get(props.data)}} />
    }

    let price = props.data.price
    let final_price = props.data.final_price
    let price_element

    var final_price_pretty = final_price > 0 ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(final_price) : "FREE"
    var price_pretty = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price)
    if (price !== final_price){    
        price_element = <p className="text"><span className=" is-blue is-cross-out">{price_pretty}</span> <span className="is-bold is-blue">{final_price_pretty} ({props.data.discount}% OFF)</span></p>
    }
    else {
        price_element = <p className="text is-blue is-bold">{price > 0 ? price_pretty : "FREE"}</p>
    }

    return (
        <React.Fragment>
            <div className="catalogue-card" key={props.data.id}>
                <img className="full-width" src={catalogue_image} />
                <div className="center catalogue-card__text">
                    <p className="text is-gold is-bold line-margin-0">{props.data.name}</p>
                    <p className="text is-blue">{props.data.dates_}</p>
                    {price_element}
                    {button}
                </div>
            </div>
        </React.Fragment>
    )
}

export default CatalogueCard