import React from 'react'

// Components
import LoginCard from '../components/containers/LoginCard'

function Login() {
    return (
        <React.Fragment>
            <LoginCard />
        </React.Fragment>
    )
}

export default Login