import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'

// Assets
import './assets/styles/variables.scss'
import './assets/styles/main.scss'

// Pages
import Pedigree from './pages/Pedigree'
import NewPedigree from './pages/NewPedigree'
import Login from './pages/Login'
import Signup from './pages/Signup'
import ForgotPassword from './pages/ForgotPassword'
import ChangePassword from './pages/ChangePassword'
import PremiumPlan from './pages/PremiumPlan'
import NewPremium from './pages/NewPremium'
import Catalogues from './pages/Catalogues'
import AdminFunctionalities from './pages/AdminFunctionalities'
import MembershipPayment from './pages/MembershipPayment'
import ManageSubscription from './pages/ManageSubscription'
import Home from './pages/Home'
import config from './api/config'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: config.gtmId
}

let isTestEnv = window.location.origin.includes('test') || window.location.origin.includes('local')

!isTestEnv && TagManager.initialize(tagManagerArgs)


function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/change-password/:token" component={ChangePassword} />
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute exact path="/pedigree-analysis" component={Pedigree} />
        <PrivateRoute exact path="/membership" component={MembershipPayment} />
        <PrivateRoute exact path="/manage-subscription" component={ManageSubscription} />
        <PrivateRoute exact path="/new-pedigree" component={NewPedigree} />
        <PrivateRoute exact path="/premium" component={PremiumPlan} />
        <PrivateRoute exact path="/new-premium" component={NewPremium} />
        <PrivateRoute exact path="/catalogues" component={Catalogues} />
        <PrivateRoute exact path="/functionalities-for-admin" component={AdminFunctionalities} admin={true} />
      </Switch>
    </BrowserRouter>
  )
}

export default App;
