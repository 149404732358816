import React, {useState, useEffect} from 'react'

// Components
import Button from '../elements/Button'
import Modal from '../elements/Modal'
import InputField from '../elements/InputField'
import Table from '../elements/Table'

// API
import {userService} from '../../api'


function ViewUsers() {
    const [users, setUsers] = useState([])


    useEffect(()=>{
        userService.list()
        .then(response => {
            setUsers(response)
        })
    }, [])

    var headers = <React.Fragment>
                    <th className="text is-black left">Name</th>
                    <th className="text is-black left">Email</th>
                    <th className="text is-black left">Membership<br/>status</th>
                    <th className="text is-black left">Reports</th>
                    <th className="text is-black left">Catalogues</th>
                    <th className="text is-black left">Premium</th>
                    <th className="text is-black left">is Admin</th>
                </React.Fragment>

    let rows = []
    for (const user of users) {
        rows.push(
            <tr className="table-rows" key={user.id}>
                <td className="text is-black left">{user.first_name} {user.last_name}</td>
                <td className="left mx-10 is-primary is-bold">{user.email}</td>
                <td className="text is-black left">{user.membership ? user.membership.status : 'inactive'}</td>
                <td className="text is-black left">{user.membership_len}</td>
                <td className="text is-black left">{user.catalogues_len}</td>
                <td className="text is-black left">{user.premium_len}</td>
                <td className="text is-black left">{user.is_staff ? "True" : null}</td>
            </tr>
        )
    }

    return (
        <React.Fragment>
                <h2 className="title is-blue caps">Registered Users</h2>
                <Table className="full-width"  headers={headers} rows={rows} />
        </React.Fragment>
    )
}

export default ViewUsers