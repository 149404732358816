import React, {useState, useEffect} from 'react'

// API
import {paymentMethodService} from '../../api'

function ViewSubscription(props) {
    const [payment, setPayment] = useState(null)

    useEffect(() => {
        paymentMethodService.retrieve()
        .then(response => {
            setPayment(response)
        })
        .catch(error => {
            console.error(error)
        })
    }, [])

    if (!props.data || !payment) {
        return (
            <React.Fragment>
                <div className="flexer">
                    <p className="flexer-3 text caps is-black is-bold">Plan details</p>
                </div>
                <hr className="my-20"></hr>
                <div className="flexer">
                    <p className="flexer-3 text caps is-black is-bold">Billing period</p>
                </div>
                <hr className="my-20"></hr>
                <div className="flexer">
                    <p className="flexer-3 text caps is-black is-bold">Billing details</p>
                </div>
            </React.Fragment>
        )
    } else {
        var startDate = new Date(props.data.current_period_start * 1000)
        var endDate = new Date(props.data.current_period_end * 1000)

        var status
        var endDateName
        if (props.data.cancel_at_period_end) {
            status = "cancelled"
            endDateName = "Membership ends"
        } else {
            status = props.data.status
            endDateName = "Next billing date"
        }

        return (
            <React.Fragment>
                <div className="flexer">
                    <p className="flexer-3 text caps is-black is-bold">Plan details</p>
                    <div className="flexer-7">
                        <p className="text is-black is-bold">{props.data.plan.product.name}</p>
                        <p className="text is-black">Status: <span className="is-gold is-bold">{status}</span></p>
                        <p className="text is-black">{`Type: ${props.data.plan.nickname}`}</p>
                        <p className="text is-black">{`Description: ${props.data.plan.metadata.description}`}</p>
                        <p className="text is-black">{`Report limit: ${props.data.plan.metadata.standard}`}</p>
                        {props.data.plan.metadata.premium ? <p className="text is-black">{`Premium Report limit: ${props.data.plan.metadata.premium}`}</p> : null }
                        {props.data.plan.metadata['one-to-one'] ? <p className="text is-black">{`One to One: ${props.data.plan.metadata['one-to-one']}`}</p> : null}
                        {/* <p className="text is-gold underline cursor" onClick={() => {props.onUpdate("update")}}>Update Membership Plan</p> */}
                    </div>
                </div>
                <hr className="my-20"></hr>
                <div className="flexer">
                    <p className="flexer-3 text caps is-black is-bold">Billing period</p>
                    <div className="flexer-7">
                        <p className="text is-black">{`Membership start date: ${startDate.getDate()}/${(startDate.getMonth()+1)}/${startDate.getFullYear()}`}</p>
                        <p className="text is-black">{`${endDateName}: ${endDate.getDate()}/${(endDate.getMonth()+1)}/${endDate.getFullYear()}`}</p>
                    </div>
                </div>
                <hr className="my-20"></hr>
                <div className="flexer">
                    <p className="flexer-3 text caps is-black is-bold">Billing details</p>
                    <div className="flexer-7">
                        <p className="text is-black is-bold">Credit card</p>
                        <p className="text is-black">{`Brand: ${payment.card.brand}`}</p>
                        <p className="text is-black">{`Last 4-digits: ****${payment.card.last4}`}</p>
                        <p className="text is-black">{`Expiration date: ${payment.card.exp_month}/${payment.card.exp_year}`}</p>
                        <p className="text is-gold underline cursor" onClick={() => {props.onUpdate("change")}}>Change Card</p>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default ViewSubscription