import React, {useState, useEffect} from 'react'

// Components
import TreeLines from './TreeLines'
import { Multiselect } from 'multiselect-react-dropdown'
import TreeItem from './TreeItem'

//API
import {horseService} from '../../api'

function PedigreeTree(props) {
    const [sires, setSires] = useState([])
    const [dams, setDams] = useState([])
    const [sireAncestry, setSireAncestry] = useState(null)
    const [damAncestry, setDamAncestry] = useState(null)
    const [refresh, setRefresh] = useState(0)
    const [keywordSire, setKeywordSire] = useState('')
    const [keywordDam, setKeywordDam] = useState('')
    const [emptyMessage, setEmptyMessage] = useState('Type at least 3 characters')

    useEffect(() => {
        if (keywordSire.trim() === '') return;
        
        if(keywordSire.length >= 3){
            setEmptyMessage('Searching for horses...')
        }
        else{
            setEmptyMessage('Type at least 3 characters')
        }

        // Debounce mechanism: Set a delay before making the request
        const delayDebounceFn = setTimeout(() => {
          handleChangeSire(keywordSire)
        }, 500); // Adjust the debounce time as needed (500ms in this example)
    
        // Cleanup function to cancel the previous API call if the user types again
        return () => clearTimeout(delayDebounceFn);
      }, [keywordSire]);

    useEffect(() => {
        if (keywordDam.trim() === '') return;
        
        if(keywordDam.length >= 3){
            setEmptyMessage('Searching for horses...')
        }
        else{
            setEmptyMessage('Type at least 3 characters')
        }

        // Debounce mechanism: Set a delay before making the request
        const delayDebounceFn = setTimeout(() => {
            handleChangeDam(keywordDam)
        }, 500); // Adjust the debounce time as needed (500ms in this example)
    
        // Cleanup function to cancel the previous API call if the user types again
        return () => clearTimeout(delayDebounceFn);
      }, [keywordDam]);


    function handleChangeSire(keyword) {
        if(keyword.length >= 3){
            horseService.find({keyword})
            .then((response)=>{
                setSires(response)
                if(response.length === 0) {
                    setEmptyMessage('No horses found')
                }
                else{
                    setEmptyMessage('Type at least 3 characters')
                }
            })
        }
    }

    function handleChangeDam(keyword) {
        if(keyword.length >= 3){
            horseService.find({keyword})
            .then((response)=>{
                setDams(response)
                if(response.length === 0) {
                    setEmptyMessage('No horses found')
                }
                else{
                    setEmptyMessage('Type at least 3 characters')
                }
            })
        }
    }

    async function handleHorseSelect(id, horseType, oldAncestry) {

        let initialHorse = null
        if (horseType === "sire_sire" || horseType === "dam_sire"){
            initialHorse = 'horse_sire'
        }
        else if (horseType === "sire_dam" || horseType === "dam_dam"){
            initialHorse = 'horse_dam'
        }

        var finalSelection = {}

        await horseService.ancestry(id, initialHorse)
        .then((response) => {
            if (response.ancestry.length > 0) {
                var ancesters = {}
                for (var anc of response.ancestry) {
                    ancesters[anc.position] = anc.ancestry_horse
                }

                finalSelection = {...oldAncestry, ...ancesters}
                if(horseType.startsWith("sire")) {
                    setSireAncestry(finalSelection)
                }
                else if (horseType.startsWith("dam")){
                    setDamAncestry(finalSelection)
                }
                
            }
            props.onSelect(response.id, horseType)
        })
        .catch((error) => {
            console.log(error)
        })

        return finalSelection
    }

    function handleHorseRemove(horseType) {
        if(horseType.startsWith("sire")){
            setSireAncestry(null)
            let currentSelected= {}
            if(props.selected.sire?.length && horseType !== "sire"){
                currentSelected = handleHorseSelect(props.selected.sire, "sire", currentSelected)
            }
            
            if(props.selected.sire_dam?.length && horseType !== "sire_dam"){
                currentSelected = handleHorseSelect(props.selected.sire_dam, "sire_dam", currentSelected)
            }
            
            if(props.selected.sire_sire?.length && horseType !== "sire_sire"){
                currentSelected = handleHorseSelect(props.selected.sire_sire, "sire_sire", currentSelected)
            }
        }
        else if(horseType.startsWith("dam")){
            setDamAncestry(null)
            let currentSelected= {}
            if(props.selected.dam?.length && horseType !== "dam"){
                currentSelected = handleHorseSelect(props.selected.dam, "dam", currentSelected)
            }
            
            if(props.selected.dam_dam?.length && horseType !== "dam_dam"){
                currentSelected = handleHorseSelect(props.selected.dam_dam, "dam_dam", currentSelected)
            }
            
            if(props.selected?.dam_sire.length && horseType !== "dam_sire"){
                currentSelected = handleHorseSelect(props.selected.dam_sire, "dam_sire", currentSelected)
            }
        }

        props.onSelect(null, horseType)
        
        setRefresh(refresh + 1)
    }


    // Grandparents
    let grandparents = (
        <React.Fragment>
            {sireAncestry?.[1]? <TreeItem type="sire" value={sireAncestry ? sireAncestry[1]?.display_name : ""} /> : <div className="tree__row flexer flexer-vcenter" ><Multiselect onSelect={(e)=>handleHorseSelect(e[0].id, "sire_sire", sireAncestry)} onRemove={()=>handleHorseRemove('sire_sire')} onSearch={setKeywordSire} options={sires} selectionLimit={1} placeholder="Select a grandsire" hidePlaceholder={true} displayValue="display_name" emptyRecordMsg={emptyMessage} /></div> }
            {sireAncestry?.[2]? <TreeItem type="sire" value={sireAncestry ? sireAncestry[2]?.display_name : ""} /> : <div className="tree__row flexer flexer-vcenter" ><Multiselect onSelect={(e)=>handleHorseSelect(e[0].id, "sire_dam", sireAncestry)} onRemove={()=>handleHorseRemove('sire_dam')} onSearch={setKeywordSire} options={sires} selectionLimit={1} placeholder="Select a dam of sire" hidePlaceholder={true} displayValue="display_name" emptyRecordMsg={emptyMessage} /></div> }
            {damAncestry?.[1]? <TreeItem type="dam" value={damAncestry ? damAncestry[1]?.display_name : ""} /> : <div className="tree__row flexer flexer-vcenter" ><Multiselect onSelect={(e)=>handleHorseSelect(e[0].id, "dam_sire", damAncestry)} onRemove={()=>handleHorseRemove('dam_sire')} onSearch={setKeywordDam} options={dams} selectionLimit={1} placeholder="Select a broodmare Sire" hidePlaceholder={true} displayValue="display_name" emptyRecordMsg={emptyMessage} /></div> }
            {damAncestry?.[2]?<TreeItem type="dam" value={damAncestry ? damAncestry[2]?.display_name : ""} /> : <div className="tree__row flexer flexer-vcenter" ><Multiselect onSelect={(e)=>handleHorseSelect(e[0].id, "dam_dam", damAncestry)} onRemove={()=>handleHorseRemove('dam_dam')} onSearch={setKeywordDam} options={dams} selectionLimit={1} placeholder="Select a second dam" hidePlaceholder={true} displayValue="display_name" emptyRecordMsg={emptyMessage} /></div> }
        </React.Fragment>
    )

    return (
        <React.Fragment>
            <div className="tree flexer flexer-nowrap">
                <TreeLines />
                <div className="tree__col flexer-2" col="1">
                    <div className="tree__row flexer flexer-vcenter">
                        <Multiselect onSelect={(e)=>handleHorseSelect(e[0].id, "sire", sireAncestry)} onRemove={()=>handleHorseRemove('sire')} onSearch={setKeywordSire} options={sires} selectionLimit={1} placeholder="Select a sire" hidePlaceholder={true} displayValue="display_name" emptyRecordMsg={emptyMessage} />
                    </div>
                    <div className="tree__row flexer flexer-vcenter">
                        <Multiselect onSelect={(e)=>handleHorseSelect(e[0].id, "dam", damAncestry)} onRemove={()=>handleHorseRemove('dam')} onSearch={setKeywordDam} options={dams} selectionLimit={1} placeholder="Select a dam" hidePlaceholder={true} displayValue="display_name" emptyRecordMsg={emptyMessage} />
                    </div>
                </div>
                <div className="tree__col flexer-2" col="2">
                    {grandparents}
                </div>
                <div className="tree__col flexer-2" col="3">
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[3]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[4]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[5]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[6]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[3]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[4]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[5]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[6]?.display_name : ""} />
                </div>
                <div className="tree__col flexer-2" col="4">
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[7]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[8]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[9]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[10]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[11]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[12]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[13]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[14]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[7]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[8]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[9]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[10]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[11]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[12]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[13]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[14]?.display_name : ""} />
                </div>
                <div className="tree__col flexer-2" col="5">
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[15]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[16]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[17]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[18]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[19]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[20]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[21]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[22]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[23]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[24]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[25]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[26]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[27]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[28]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[29]?.display_name : ""} />
                    <TreeItem type="sire" value={sireAncestry ? sireAncestry[30]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[15]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[16]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[17]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[18]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[19]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[20]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[21]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[22]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[23]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[24]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[25]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[26]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[27]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[28]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[29]?.display_name : ""} />
                    <TreeItem type="dam" value={damAncestry ? damAncestry[30]?.display_name : ""} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default PedigreeTree