import React, {useState} from 'react'
import {Link, Redirect} from 'react-router-dom'

// Assets
import Logo from '../../assets/images/logo.png'
import LoginImage from '../../assets/images/login-image.jpg'

// Components
import InputField from '../elements/InputField'
import Button from '../elements/Button'
import SocialMediaButtons from "./SocialMediaButtons";

//API
import {authenticationService} from '../../api'

function LoginCard() {
    const [redirect, setRedirect] = useState(false)
    const [errors, setErrors] = useState({})

    function handleSubmit(e) {
        e.preventDefault()

        var form_element = document.getElementById('login-form')
        var form_data = new FormData(form_element)

        authenticationService.login(form_data)
            .then(response => {
                setRedirect(true)
            })
            .catch(error => {
                setErrors({...error})
            })
    }

    if (redirect) {
        return <Redirect to="/"/>
    }

    return (
        <div className="reg-card half-width screen-center full-mobile">
            <div className="flexer flexer-spaced flexer-mobile">
                <div className="flexer-5 center">
                    <img className="one-third-width mt-30 mb-20" src={Logo} alt="GeneUse"/>
                    <div className="mx-10"><h1 className="huge-letters is-black line-margin-0"><span
                        className="is-bold">welcome</span> back</h1></div>
                    <p className="subtext line-margin-0 px-30">Thank you for getting back, please login to your
                        account.</p>
                    <form id="login-form" className="my-20 mx-20" onSubmit={handleSubmit}>
                        <InputField name="email" type="email" label="Username" error={errors.email}/>
                        <InputField name="password" type="password" label="Password"
                                    error={errors.password || errors.non_field_errors || errors.detail}/>
                        <p className="line-margin-0 right"><Link className="subtext link is-gold" to="/forgot-password">Forgot
                            password?</Link></p>
                        <Button text="Sign in" class="half-width mt-30" type="submit"/>

                        <SocialMediaButtons type="login"/>
                    </form>
                    <p className="subtext is-black mb-30">New user?&nbsp;
                        <a className="link is-gold" href="/signup">Sign Up</a>
                    </p>
                </div>
                <img className="half-width full-mobile" src={LoginImage} alt="GeneUse"/>
            </div>
        </div>
    )
}

export default LoginCard
