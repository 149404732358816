import React from 'react'

// Assets
import Loader from '../../assets/images/loader.gif'

function Button(props) {

    var icon
    if (props.icon) {
        icon = <i className={`mr-10 ${props.icon}`}></i>
    }

    if (props.loading) {
        return (
            <React.Fragment>
                <button type={props.type} className={`${props.class} button`} disabled={true}><img src={Loader} /></button>
            </React.Fragment>
        )
    } else {
        if (props.inactive) {
            return (
                <React.Fragment>
                    <button type={props.type} className={`${props.class} button`} disabled={true} onClick={props.onClick}>{icon}{props.text}</button>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <button type={props.type} className={`${props.class} button`} onClick={props.onClick}>{icon}{props.text}</button>
                </React.Fragment>
            )
        }
    }
}

export default Button