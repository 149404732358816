import React from 'react'

function SidebarItem(props) {
    var active = ''
    if (props.active) {
        active = 'sidebar__item__active'
    }
    
    return (
        <React.Fragment>
            <div className={`sidebar__item sidebar__item__${props.item} is-relative ${active}`}>
                <i className={`${props.icon} is-white`} />
                <div className="bubble">
                    <p className="line-margin-0 is-white subtext bubble__text">{props.text}</p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SidebarItem