import React, {useState} from 'react'

// Stripe
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js'

// Components
import Button from '../elements/Button'
import InputField from '../elements/InputField'

// API
import {subscriptionService, paymentService} from '../../api'

function CreditCardInfo(props) {
    const [loading, setLoading] = useState(false)
    const [billingDetails, setBillingDetails] = useState({phone: "", name: ""})
    const [error, setError] = useState(null)

    const CARD_OPTIONS = {
        iconStyle: "solid",
        style: {
          base: {
            iconColor: "#C19C51",
            color: "#272727",
            fontWeight: 500,
            fontFamily: "proxima-nova', sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
              color: "#272727"
            },
            "::placeholder": {
              color: "#737373"
            }
          },
          invalid: {
            iconColor: "red",
            color: "red"
          }
        }
    }

    const stripe = useStripe()
    const elements = useElements()

    if (!stripe || !elements) {
        return ''
    }

    function handleSubmit(e) {
        e.preventDefault()

        setError(null)
        setLoading(true)

        if (props.type === "subscription" || props.type === "standalone" ) {
            var formData = new FormData
            formData.append("price_id", props.data.price_id)

            if(props.type === "standalone") {
                formData.append("type", props.type);
                formData.append("n_reports", props.body.n_reports);
            } 
            
            subscriptionService.create(formData)
            .then(async (response) => {
                const cardElement = elements.getElement(CardElement)

                let { error, paymentIntent } = await stripe.confirmCardPayment(response.client_secret, {
                    payment_method: {
                        card: cardElement,
                        billing_details: {
                            name: billingDetails.name
                        }
                    }
                })

                if (error) {
                    setLoading(false)
                    setError(error.message)
                } else {
                    setTimeout(function(){ props.onUpdate({}, 3) }, 5000);
                }
            })
            .catch(error => {
                setLoading(false)
                setError(error.non_field_error)
            })
        }
        else if (props.type === "payment"){
            
            paymentService.create(props.body)
            .then(async (response) => {
                const cardElement = elements.getElement(CardElement)

                let { error, paymentIntent } = await stripe.confirmCardPayment(response.client_secret, {
                    payment_method: {
                        card: cardElement,
                        billing_details: {
                            name: billingDetails.name
                        }
                    }
                })

                if (error) {
                    setLoading(false)
                    setError(error.message)
                } else {
                    setTimeout(function(){ props.onUpdate({}, 3) }, 5000);
                }
            })
            .catch(error => {
                setLoading(false)
                setError(error.non_field_error)
            })
        }
    }

    return (
        <React.Fragment>
            <div className="center">
                <h2 className="huge-letters is-black">Select <span className="is-bold">a Plan</span></h2>
                <div className="flexer center flexer-mobile">
                    <div className="component-card flexer-4 mx-10">
                        <p className="subtitle is-black light-bold left mb-40">Payment method</p>
                        <form className="Form" onSubmit={handleSubmit}>
                            <InputField class="mb-40" name="full_name" type="text" label="Full Name" value={billingDetails.name} onChange={(e) => {setBillingDetails({...billingDetails, name: e.target.value})}} />
                            <InputField class="mb-40" name="phone" type="number" label="Phone" value={billingDetails.phone} onChange={(e) => {setBillingDetails({...billingDetails, phone: e.target.value})}} />
                            <div className="mb-30" style={{borderBottom: "1px solid var(--lines)"}}>
                                <CardElement  options={CARD_OPTIONS} />
                            </div>
                            {error ? <p className="error left mb-20"><i className="fa fa-warning mr-10"></i>{error}</p> : null }
                            <Button type="submit" text="Select" class="mt-10 full-width" loading={loading} />
                            <p className="subtext is-light left"><i className="fa fa-lock mr-10"></i>Your transaction is secured with SSL encryption</p>
                        </form>
                    </div>
                    <div className="component-card flexer-4 mx-10">
                        <p className="subtitle is-black left">Summary</p>
                        <div className="flexer flexer-spaced">
                            <p className="text is-black left">{props.data.plan} {props.data.nickname? `(${props.data.nickname})`: null}</p>
                            <p className="text is-black">{props.data.pretty_price}</p>
                        </div>
                        <p className="subtext is-light left line-margin-0">{props.data.description}</p>
                        <hr className="my-20"></hr>
                        <div className="flexer flexer-spaced">
                            <p className="text is-black line-margin-0">VAT</p>
                            <p className="text is-black line-margin-0">USD 0.00</p>
                        </div>
                        <hr className="my-20"></hr>
                        <div className="flexer flexer-spaced">
                            <p className="text is-black is-bold">Total</p>
                            <p className="text is-black is-bold">{props.data.pretty_price}</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CreditCardInfo