import React, {useState} from 'react'
import {Link, Redirect, useParams} from 'react-router-dom'

// Assets
import Logo from '../../assets/images/logo.png'
import signupImage from '../../assets/images/signup-image.jpg'

// Components
import InputField from '../elements/InputField'
import Button from '../elements/Button'

// API
import {authenticationService} from '../../api'

function ChangePasswordCard() {
    const [completed, setCompleted] = useState(false)
    const [errors, setErrors] = useState({})
    let {token} = useParams()

    console.log("token", token)
    function handleSubmit(e) {
        e.preventDefault()

        var form_element = document.getElementById('change-password-form')
        var form_data = new FormData(form_element)

        authenticationService.changePassword(form_data)
        .then(response => {
            setCompleted(true)
        })
        .catch(error => {
            setErrors({...error})
        })
    }

    let form_section = (
        <form id="change-password-form" className="my-20 mx-20" onSubmit={handleSubmit}>
            <InputField name="password" type="password" label="Password" error={errors.password} />
            <InputField name="password2" type="password" label="Repeat Password" error={errors.password2 || errors.non_field_errors} />
            <InputField name="token" type="hidden" value={token} error={errors.token} />
            <Button text="Set Password" class="two-third-width mt-30" type="submit" />
        </form>
    )
    if (completed) {
        form_section = <p>Your password was changed succesfully. You can try and login again.</p>
    }
    return (
        <div className="reg-card half-width screen-center full-mobile">
            <div className="flexer flexer-spaced flexer-vcenter flexer-mobile">
                <div className="flexer-5 center">
                    <img className="one-third-width mt-30 mb-20" src={Logo} alt="GeneUse" />
                    <div className="mx-10"><h1 className="huge-letters is-black line-margin-0">change <span className="is-bold">password</span></h1></div>
                    <p className="subtext px-30">Please set your new password.</p>
                    {form_section}
                    <p className="subtext is-black mb-30">Already have an account?&nbsp;
                        <Link className="link is-gold" to="/login">Log In</Link>
                    </p>
                </div>
                <img className="half-width full-mobile" src={signupImage} alt="GeneUse" />
            </div>
        </div>
    )
}

export default ChangePasswordCard