import config from './config'
import { handleResponse } from './handle-response'
import { checkToken, authHeader } from './helpers'

export const membershipReports = {
    details,
    create_report,
    delete_report
}

async function details() {

    await checkToken()

    const requestOptions = { method: 'GET', headers: authHeader()}
    return fetch(`${config.apiUrl}/membership-reports/details/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

async function create_report(body) {

    await checkToken()

    const requestOptions = { method: 'POST', headers: {...authHeader(), "Content-Type": "application/json"}, body: JSON.stringify(body)}
    return fetch(`${config.apiUrl}/membership-reports/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

async function delete_report(report_id) {

    await checkToken()

    const requestOptions = { method: 'DELETE', headers: authHeader()}
    return fetch(`${config.apiUrl}/membership-reports/${report_id}/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}