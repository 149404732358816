import React, {useState} from 'react'
import {Link} from 'react-router-dom'

// Components
import MainStructure from '../components/containers/MainStructure'
import InputField from '../components/elements/InputField'
import Textarea from '../components/elements/Textarea'
import Modal from '../components/elements/Modal'
import Button from '../components/elements/Button'

// API
import {premiumReports} from '../api'


function NewPremium() {
    const [isOpen, setIsOpen] = useState(false)
    const [exampleModal, setExampleModal] = useState(false)
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)

    function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)

        const form = document.getElementById('new-premium-model-form')
        const body = new FormData(form)

        premiumReports.create(body)
        .then(response => {
            console.log(response)
            setIsOpen(true)
            setErrors({})
            setLoading(false)
        })
        .catch(error => {
            setErrors(error)
            console.error("It was not possible to create premium request.")
            setLoading(false)
        })
    }

    return (
        <React.Fragment>
            <MainStructure>
                <div className="center">
                    <h2 className="huge-letters is-black">New <span className="is-bold">Request</span></h2>
                    <form id="new-premium-model-form" onSubmit={handleSubmit}>
                        <div className="card center">
                            <h2 className="subtitle is-black mb-30">New Analysis</h2>
                            <div className="flexer">
                                <div className="steps mx-10"><p className="line-margin-0 center">1</p></div>
                                <InputField name="name" class="line-margin-0 one-fourth-width remaining-mobile" label="Pedigree Name" error={errors.name}/>
                            </div>
                        </div>
                        <br />
                        <div className="card center">
                            <div className="flexer mt-30">
                                <div className="steps mx-10"><p className="line-margin-0 center">2</p></div>
                                <div className="left line-margin-0 half-width remaining-mobile">
                                    <label className="input-field__label" >Please enter the details of the study you want to make. <i className="fa fa-question modal-icon-small cursor" onClick={() => {setExampleModal(true)}} /></label>
                                    <textarea rows='5' className="input-field__input" name="description"/>
                                    <p className="error">{errors.description || errors.non_field_error}</p>
                                </div>
                            </div>
                            <div className="center one-fifth-width full-mobile">
                                <Button class="mt-30 caps" type="submit" text="Generate report" icon="fas fa-folder-plus"/>
                            </div>
                        </div>
                    </form>
                </div>
            </MainStructure>
            <Modal isOpen={isOpen}>
                <div className="center">
                    <h2 className="subtitle is-black">Request submitted</h2>
                    <p className="text is-bold mx-20 mb-30 line-margin-0">Thank you for your request</p>
                    <p className="text is-light-weight mx-30 mb-30">We will review it and generate the premium request as soon as possible</p>
                    <Link to="premium" className="button"><i className="fa fa-check mr-10"></i>OK</Link>
                </div>
            </Modal>
            <Modal isOpen={exampleModal}>
                <div className="center">
                    <i className="modal-icon fa fa-question mb-10" />
                    <p className="text is-light-weight mx-20 mb-30">Indicate one stallion vs a group of mares, one mare vs your preferred stallions, a group of stallions and a group of mares (and all possible combinations) or just indicate a list of hypothetical matings for us to run for you.</p>
                    <p className="button center one-sixth-width" onClick={()=> {setExampleModal(false)}}><i className="fa fa-check mr-10"></i>OK</p>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default NewPremium