import { BehaviorSubject } from 'rxjs'

import config from './config'
import { handleResponse } from './handle-response'

//API
import { userService } from './user.service'

//JWT
import jwt_decode from 'jwt-decode'

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')))

export const authenticationService = {
    login,
    logout,
    refreshToken,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value },
    forgotPassword,
    changePassword,
    loginFacebook,
    loginGoogle
}

function login(body) {
    const requestOptions = { method: 'POST', body }

    return fetch(`${config.apiUrl}/auth/token/`, requestOptions)
        .then(handleResponse)
        .then(async response => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            const token = jwt_decode(response['access'])
            console.log("token regular", token)
            response = {
                ...response,
                expiry: token['exp'],
            }
            localStorage.setItem('currentUser', JSON.stringify(response))
            currentUserSubject.next(response)

            await userService.details()
            .then(user => {
                response = {...response, user}
                localStorage.setItem('currentUser', JSON.stringify(response))
                currentUserSubject.next(response)
            })
            

            return response
        })
        
}

function loginFacebook(body) {
    const requestOptions = { method: 'POST', headers: { "Content-Type": "application/json"}, body: JSON.stringify(body)  }

    return fetch(`${config.apiUrl}/oauth/facebook/`, requestOptions)
        .then(handleResponse)
        .then(async response => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            const token = jwt_decode(response['access_token'])
            console.log("token fb", token)
            response = {
                ...response,
                expiry: token['exp'],
            }
            localStorage.setItem('currentUser', JSON.stringify(response))
            currentUserSubject.next(response)

            await userService.details()
            .then(user => {
                response = {...response, user}
                localStorage.setItem('currentUser', JSON.stringify(response))
                currentUserSubject.next(response)
            })
            

            return response
        })
        
}

function loginGoogle(body) {
    const requestOptions = { method: 'POST', headers: { "Content-Type": "application/json"}, body: JSON.stringify(body)  }

    return fetch(`${config.apiUrl}/oauth/google/`, requestOptions)
        .then(handleResponse)
        .then(async response => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            const token = jwt_decode(response['access_token'])
            console.log("token fb", token)
            response = {
                ...response,
                expiry: token['exp'],
            }
            localStorage.setItem('currentUser', JSON.stringify(response))
            currentUserSubject.next(response)

            await userService.details()
                .then(user => {
                    response = {...response, user}
                    localStorage.setItem('currentUser', JSON.stringify(response))
                    currentUserSubject.next(response)
                })


            return response
        })

}

async function refreshToken() {
    const tokens = authenticationService.currentUserValue
    const refresh = tokens['refresh']
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh })
    }

    await fetch(`${config.apiUrl}/auth/token/refresh/`, requestOptions)
        .then((response) => {
            return handleResponse(response)
        })
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            const token = jwt_decode(user['access'])
            user = {
                ...tokens,
                ...user,
                expiry: token['exp'],
            }
            localStorage.setItem('currentUser', JSON.stringify(user))
            currentUserSubject.next(user)

            return user
        })
        .catch(error => {
            console.log("failing refreshing token", error)
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    window.location.reload()
}

function forgotPassword(body){
    const requestOptions = { method: 'POST', body }

    return fetch(`${config.apiUrl}/auth/forgot_password/`, requestOptions)
        .then(handleResponse)
        .then(async response => {
            return response
        })
}

function changePassword(body){
    const requestOptions = { method: 'POST', body }

    return fetch(`${config.apiUrl}/auth/change_password/`, requestOptions)
        .then(handleResponse)
        .then(async response => {
            return response
        })
}