import React from 'react'

function LeftMenu(props) {

    var catalogueActive = ""
    if (props.active === "catalogue") {
        catalogueActive = "left-menu__active-item"
    }

    var modelActive = ""
    if (props.active === "ml-model") {
        modelActive = "left-menu__active-item"
    }

    var userActive = ""
    if (props.active === "user-management") {
        userActive = "left-menu__active-item"
    }
    return (
        <div className="mx-30">
            <p className="is-primary is-bold">MENU</p>
            <div onClick={() => {props.onClick("ml-model")}} className={`left-menu__item ${modelActive} flexer flexer-vcenter cursor`}>
                <i className="fa fa-refresh mx-10 is-primary"></i>
                <p>Update ML model</p>
            </div>
            <div onClick={() => {props.onClick("user-management")}} className={`left-menu__item ${userActive} flexer flexer-vcenter cursor mb-20`}>
                <i className="fa fa-user mx-10 is-primary"></i>
                <p>User Management</p>
            </div>
        </div>
    )
}

export default LeftMenu