import config from './config';
import { handleResponse } from './handle-response';
import { checkToken, authHeader } from './helpers'

export const horseService = {
    find,
    ancestry,
    //retrieve
}


async function retrieve(user_id) {

    await checkToken()

    const requestOptions = { method: 'GET', headers: authHeader() }
    
    return fetch(`${config.apiUrl}/users/${user_id}/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

async function find(body) {

    //await checkToken()

    const requestOptions = { method: 'POST', headers: {...authHeader(), 'Content-Type': 'application/json'}, body: JSON.stringify(body)}
    
    return fetch(`${config.apiUrl}/horses/find/`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user
        })
}

async function ancestry(horse_id, horse_type) {

    //await checkToken()

    let params = {
        horse_sire: horse_type === 'horse_sire',
        horse_dam: horse_type === 'horse_dam'
    }
    params = new URLSearchParams(params).toString()
    const requestOptions = { method: 'GET', params: params, headers: authHeader() }
    
    return fetch(`${config.apiUrl}/horses/${horse_id}/?${params}`, requestOptions)
        .then(handleResponse)
        .then(ancestry => {
            return ancestry
        })
}

