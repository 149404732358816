import React, {useState, useEffect} from 'react'

// Components
import Card from '../elements/Card'

// API
import {plansService} from '../../api'

function MembershipPlans(props) {
    const [plans, setPlans] = useState({})
    const [plansByReportsNumber, setPlansByReportsNumber] = useState({})
    const [activeTab, setActiveTab] = useState("subscription");
    const [isPremium, setIsPremium] = useState(false);
    const [recurring, setRecurring] = useState("year");

    useEffect(() => {
        plansService.list()
        .then(response => {
            const subscriptions = Object.values(response.subscriptions).sort(
                ((a, b) => {
                    const priceToOrderA = Object.values(a.prices).findIndex(
                        (price) => {
                            return price.recurring.interval === "year"
                        }
                    );
                    const priceToOrderB = Object.values(b.prices).findIndex(
                        (price) => {
                            return price.recurring.interval === "year"
                        }
                    );

                    return a.prices[priceToOrderA].unit_amount - b.prices[priceToOrderB].unit_amount
                })
            );
            const standalone = Object.values(response.standalone).sort(((a, b) => a.prices[0].unit_amount - b.prices[0].unit_amount));

            setPlans(subscriptions);
            setPlansByReportsNumber(standalone);
        })
        .catch(error => {
            console.error(error)
        })
    }, [])

    let contentTwo = []
    var pnum = 1;
    const handleFilterPlan = (e) => {
        const selected = e.target.value;
        if (selected === "premium") setIsPremium(true);
        if (selected === "standard") setIsPremium(false);
        if (selected != "premium" && selected != "standard") setRecurring(selected);
        createCards();
    }

    let content = [];
    const createCards = () => {
        if(activeTab === "subscription") {
            for (const plan of Object.values(plans)) {
                const prices =  plan.prices
                const priceToShow =  Object.values(prices).filter(
                    (price) => {
                        if(recurring === "year") return price.recurring.interval === recurring
                        if(recurring === "month") return price.recurring.interval === recurring && price.recurring.interval_count === 1
                        if(recurring === "quarter") return price.recurring.interval === "month" && price.recurring.interval_count === 3
                    }
                )[0];

                content.push(
                    <Card name={plan.nickname}
                        pnum = {pnum}
                        priceId={priceToShow.id}
                        membershipName={plan.name}
                        description={plan.description}
                        price={priceToShow.unit_amount/100}
                        freq={recurring === "quarter" ? `3 ${priceToShow.recurring.interval}s` : priceToShow.recurring.interval}
                        currency={priceToShow.currency}
                        onUpdate={props.onUpdate}
                        type="new"
                        features={priceToShow.metadata}
                    />
                )
                pnum += 1
            }
        } else {
            let plansToList;

            isPremium
                ? plansToList = Object.values(plansByReportsNumber).filter(key => key.metadata.type === "premium")
                : plansToList = Object.values(plansByReportsNumber).filter(key => key.metadata.type === "standard");
            for (const plan of Object.values(plansToList)) {
                contentTwo.push(
                    <Card small={true}
                    name={plan.name}
                    final_price={plan.prices[0].unit_amount/100}
                    id={plan.id}
                    n_reports={plan.prices[0].metadata.standard || plan.prices[0].metadata.premium}
                    onUpdate={props.onUpdate}
                    priceId={plan.prices[0].id}
                    pnum={pnum}
                    is_premium={isPremium}
                    />
                )
                pnum += 1

            }
        }
    }

    createCards();

    const handleTab = (activeTab) => {
        setActiveTab(activeTab);
    };

    const frequencyOptions = <>
        <option value="month">Monthly</option>
        <option value="quarter">Quarterly</option>
        <option selected value="year">Annual</option>
    </>
    const typePlanStandaloneOptions = <>
        <option value="standard">Standard</option>
        <option value="premium">Premium</option>
    </>

    return (
        <React.Fragment>
            <div className="center">
                <h2 className="huge-letters is-black">Select <span className="is-bold">a Plan</span></h2>
                <div className="flexer center flexer-mobile">
                    <div class="tabs">
                        <button className={`caps is-black  ${activeTab === "subscription" ? "activeTab" : "tab"}`} onClick={() => {handleTab("subscription")}}>Subscription</button>
                        <button className={`caps is-black  ${activeTab === "standalone" ? "activeTab" : "tab"}`} onClick={() => {handleTab("standalone")}}>StandAlone</button>
                    </div>
                </div>
                <div className="flexer center flexer-mobile">
                    <div className="frequency-container">
                        <select className="frequency-select" onChange={handleFilterPlan}>
                            {activeTab === "subscription" ? frequencyOptions : typePlanStandaloneOptions }
                        </select>
                    </div>
                </div>
                <div className="flexer center">
                    {activeTab === "subscription" ? content : contentTwo }
                </div>
            </div>
        </React.Fragment>
    )
}

export default MembershipPlans