import React, {useState} from 'react'

// Containers
import MainStructure from '../components/containers/MainStructure'
import LeftMenu from '../components/containers/LeftMenu'
import NewCatalogue from '../components/AdminFunctionalities/NewCatalogue'
import UpdateModel from '../components/AdminFunctionalities/UpdateModel'
import ViewUsers from '../components/AdminFunctionalities/ViewUsers'

function AdminFunctionalities() {
    const [activeItem, setActiveItem] = useState('ml-model')
    
    function handleMenuClick(e) {
        setActiveItem(e)
    }

    var rightContent
    if (activeItem === "catalogue") {
        rightContent = <div className="mx-30"><NewCatalogue /></div>
    } else if (activeItem === "ml-model") {
        rightContent = <div className="mx-30"><UpdateModel /></div>
    } else if (activeItem === "user-management") {
        rightContent = <div className="mx-30"><ViewUsers /></div>
    }

    return (
        <React.Fragment>
            <div className="max-screen">
                <MainStructure split={true}>
                    <div className="inner-card flexer flexer-mobile">
                        <div className="flexer-2 left-menu">
                            <LeftMenu onClick={handleMenuClick} active={activeItem} />
                        </div>
                        <div className="flexer-8">
                            {rightContent}
                        </div>
                    </div>
                </MainStructure>
            </div>
        </React.Fragment>
    )
}

export default AdminFunctionalities