import React from "react"

//Components
import Header from "./Header"
import Content from "./Content"
import Sidebar from "./Sidebar"

function MainStructure(props) {
    var splitted = false
    if (props.split) {
        splitted = true
    }
    
    return (
        <React.Fragment>
            <div className="max-screen">
                <Header />
                <div className="max-screen__bottom flexer">
                    <Sidebar />
                    <Content>
                        {props.children}
                    </Content>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MainStructure