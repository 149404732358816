import React from 'react'

function Table(props) {
    return (
        <table className={props.className} cellSpacing="0">
            <thead>
                <tr className="table-header">
                    {props.headers}
                </tr>
            </thead>
            <tbody>
                {props.rows}
            </tbody>
        </table>
    )
}

export default Table